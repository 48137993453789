<template>
    <div id="fine-uploader"></div>
</template>
<script>
import { isEmojiCharacter } from '@/utils/toolsForCommon'
import { MessageBox } from 'element-ui'
export default {
    data() {
        return {
            userId: '',
            userName: ''
        }
    },
    watch: {
        userId: {
            handler(val) {
                if (val) {
                    this.initUpload()
                }
            },
            immediate: true
        }
    },
    methods: {
        handleUpload() {
            this.userId = JSON.parse(localStorage.getItem('User_userInfo')).userId
            this.userName = JSON.parse(localStorage.getItem('User_userInfo')).username
            this.$nextTick(() => {
                $("#fine-uploader :file").click();
            })
        },
        // 初始化上传组件
        // 上传组件文档：https://note.youdao.com/ynoteshare/index.html?id=5e795f03373c77681d5a762bccacc6d1&type=note&_time=1659928073801
        // 上传组件相关问题找卢燕伟
        initUpload() {
            $('#fine-uploader').Ableuploader({
                targetId: "fine-uploader", //为按钮目标标签ID
                appName: "ablecommons",//对应应用名称
                userId: this.userId, //填写用户ID
                userName: this.userName,
                modelName: "demo", //模块名称
                autoConvert: "true",//true为自动转换，false或者不配置为不自动转换(上传需要自带文档转换,Flex上传不需要自带转换.)
                buttonWidth: "120", //IE789下设置此按钮宽度生效
                buttonHeight: "100",
                showProgress: "true", //是否显示任务栏进度条
                multipleUpload: "false",
                buttonText: "上传本地视频",
                image: {},//图片尺寸大小限制 如：{maxHeight: 300, maxWidth: 300}
                buttonClass: "",
                buttonTextStyle: "{ font-size: 14; color: #FFFFFF; display:inline-block;width:120;height:35;text-align:center; font-family: 'Microsoft YaHei';  }", // 按钮文字样式定义
                allowSuffix: "mp4",//自定义限制后缀 demo: image-->"jpg|png|bmp" video-->"flv|mp4|mp3"
                videoConvert: "true",
                // fileSizeLimit: 100 * 1024 * 1024, //文件大小限制 0代表无限制 字节B
                fileSizeLimit: 0, //文件大小限制 0代表无限制 字节B
                fileItemLimit: 0, //一次允许上传的个数，默认为0不限制
                watermark: false, //视频转码文件是否添加水印 true添加false不添加
                host: "//base1.zhihuishu.com/able-commons/",
                callbacks: {
                    onFileDialogStart: function (tid) {
                        // alert(1);
                    },
                    onUpload: function (id, fileName) {
                        if (isEmojiCharacter(fileName)) {
                            console.log(1);
                            MessageBox.alert('文件名包含非法字符，请修改后重试', '提示', {
                                confirmButtonText: '确定'
                            });
                            return false
                        }
                    }.bind(this),
                    onComplete: function (id, fileName, responseJSON) {
                        //上传完成返回事件
                        if (responseJSON.code == 200) {
                            let data = responseJSON.data
                            const params = {
                                videoId: data.fileId,
                                videoName: data.fileName,
                                videoDuration: data.size,
                                filePath: data.filePath
                            }
                            this.$emit('handleUploadSuccess', params)
                        }
                    }.bind(this)
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
#fine-uploader {
    display: none;
}
</style>