<template>
    <div class="audioAI-container" :theme="theme">
        <!-- 左侧视频区 -->
        <div class="left-area">
            <div class="video-box">
                <div class="video-wrapper">
                    <!-- 播放器容器 -->
                    <div class="video-container">
                        <!-- 示例视频tag -->
                        <!-- 产品要求示例视频干掉 -->
                        <!-- <div class="video-tag" v-if="isSample">示例视频</div> -->
                        <!-- 播放前蒙层 -->
                        <div class="video-mask" @click="handlePlay" v-if="showMask">
                            <div class="video-mask-bg"
                                :style="{backgroundImage: `url(${ curVideoItem && curVideoItem.imageUrl || require('@/assets/audioAI/video-bg.png') })`}">
                                <div class="video-mask-mantle">
                                    <div class="play-icon" v-if="videoReady"></div>
                                </div>
                            </div>
                        </div>
                        <!-- 转码中蒙层 -->
                        <div class="transcode-mask" v-if="!curVideoItem.isTranscode" v-show="!videoRecordStreamUrl">
                            <div class="transcode-mask-bg"
                                :style="{backgroundImage: `url(${ curVideoItem && curVideoItem.imageUrl || require('@/assets/audioAI/video-bg.png') })`}">
                                <div class="mask">
                                    <img src="@/assets/audioAI/water-loading.webp" />
                                    <div>视频转码、审核中…</div>
                                    <div>1-3分钟后推送转码成功的视频</div>
                                </div>
                            </div>
                        </div>
                        <!-- 播放器 -->
                        <div id="container" style="width:100%; height:450px;"></div>
                        <!-- 新的播放器区域： -->
                        <div class="video_container_new">
                            <div id="J_prismPlayer"></div>
                        </div>
                    </div>
                    <!-- 播放器下方工具栏 -->
<!--                    <div class="video-toos-bar">-->
<!--                        <div class="flex">-->
<!--                            <div class="video-title">{{ isSample ? '当前为系统示例视频' : curVideoItem && curVideoItem.title }}</div>-->
<!--                            <div class="other-class" v-if="$route.name == 'playback' && isSeries" @click="drawer = true">-->
<!--                                <i class="el-icon-link"></i>-->
<!--                                <span>系列直播课</span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="other-class-icon" v-if="$route.name == 'playback' && isSeries" @click="drawer = true">-->
<!--                            <img src="@/assets/audioAI/list-icon.png" />-->
<!--                        </div>-->
<!--                    </div>-->
                  <div class='video-select' v-if="$route.name == 'playback' && isSeries">
                    <div class='video-seTitle'>课程选集</div>
                    <div style='margin-left: 18px'></div>
                    <div v-for='(item,index) in mySeriesLiveList' :class='item.liveId ==liveId?"video-cons":"video-con"' :title='item?.liveName' @click='switchLive(item)'>
                      {{ item?.liveName }}
                    </div>
                    <div class='video-many' @click='drawer = true' v-if='!(this.seriesLiveList.length<=3)'>更多</div>
                  </div>
                    <!-- 视频列表 -->
                    <VideoList :videoList="videoList" :videoId="videoId" :isAutoCutting="isAutoCutting" @handleUpload="handleUpload"
                        @cutByMyself="cutByMyself" @switchVideo="switchVideo" :sumOfExampleVideo="sumOfExampleVideo" :liveId='liveId' />
                </div>
            </div>
        </div>
        <!-- 右侧文字区 -->
        <div class="right-area">
            <div class="text-box">
                <!-- 头部 -->
                <div class="text-box-header">
                    <div class="text-box-title">
                        <img src="@/assets/audioAI/AI-icon.png" />
                        <span>AI文字提取</span>
                    </div>
                    <!-- 搜索框 -->
                    <div class="saerch-input-box">
                        <i class="search-icon el-icon-search"></i>
                        <input class="saerch-input" placeholder="搜索" v-model="searchText" @input="handleInput" @focus="focusInput" @blur="blurInput"
                            @keyup.enter="handleSearch" />
                        <template v-if="searchDone">
                            <span class="search-result">
                                {{ this.searchDomList.length ? this.curChooseIndex+1 : 0 }}
                                <span class="search-result-diveder">/</span>
                                {{ this.searchDomList.length }}
                            </span>
                            <span class="diveder-line">|</span>
                            <i class="tools-icon el-icon-arrow-up" @click="toBefore"></i>
                            <i class="tools-icon el-icon-arrow-down" @click="toNext"></i>
                            <i class="tools-icon el-icon-error" @click="clearSearchResult"></i>
                        </template>
                    </div>
                </div>
                <!-- ai文字 -->
                <div class="subtitle-comp" v-loading="wordLoading" element-loading-text="文字加载中">
                    <template v-if="!wordLoading">
                        <template v-if="wordList && wordList.length">
                            <el-scrollbar ref="textScrollbar">
                                <div class="subtitle-scroll-container">
                                    <div class="subtitle-list-inner">
                                        <div class="text-box-content" id="text-content">
                                            <div class="paragraph-item" v-for="(item, indexForParagraph) in wordList" :key="item.id">
                                                <div class="avatar">{{ item.username && item.username.substr(-2) }}</div>
                                                <div class="paragraph-wrapper">
                                                    <div class="user-name">{{ item.username }} <span>（主讲）</span> {{ formatTime(item.beginTime) }}
                                                    </div>
                                                    <span
                                                        class="word"
                                                        :id="`word_paragraphWhere${indexForParagraph}_sentenceWhere${index}`"
                                                        :father-item-id="item.beginTime"
                                                        :self-item-id="word.wb"
                                                        :cid="word.wb"
                                                        v-for="(word, index) in item.contentList"
                                                        :key="index"
                                                        :paragraphWhere="indexForParagraph"
                                                        :sentenceWhere = "index"
                                                    >
                                                        <span v-for="(wordInfoListInnerItem, wordInfoListInnerIndex) in word.wordInfoList" :key="wordInfoListInnerIndex">{{wordInfoListInnerItem.w}}</span>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </el-scrollbar>
                        </template>
                        <!-- 空状态 -->
                        <div v-else class="empty-text">
                            <img src="@/assets/audioAI/warning-icon.png" />
                            <div>AI文字提取中…课堂助手将在1-3分钟后推送系统提取的文字</div>
                        </div>
                    </template>

                </div>
            </div>
        </div>
        <!-- 系列课 -->
        <ClassList :drawer.sync="drawer" :liveId="liveId" @switchLive="switchLive" />
    </div>
</template>

<script>
import VideoList from './components/VideoList.vue'
import ClassList from './components/ClassList.vue'
import { debounce } from '@/utils/toolsForCommon'
import { mapGetters, mapMutations } from 'vuex'
import bus from '@/utils/eventBus'
export default {
    components: {
        VideoList,
        ClassList,
    },
    props: {
        theme: {
            type: String,
            default: ''
        },
        isSample: {
            type: Boolean,
            default: false
        },
        videoList: {
            type: Array,
            default() {
                return []
            }
        },
        isSeries: {
            type: Boolean,
            default: false
        },
        isAutoCutting: {
            type: Boolean,
            default: false
        },
        isTranscode: {
            type: Boolean,
            default: false
        },
        liveId: {
            type: String | Number,
            default: ''
        },
        seriesLiveList: {
            type: Array
        },
    },
    data() {
        return {
            // isPlayerSubtitleOpen: false, // 播放器字幕状态
            allWordTime: [], //所有词
            passage: '',
            videoDom: null,
            currentDom: null, //当前高亮的节点
            currentDomCid: '0', //当前高亮的节点的cid
            searchText: '',
            searchDomList: [],
            clearClassList: [], //所有搜索标记的cid
            curChooseIndex: '', //当前选择的搜索结果的下标
            drawer: false, //系列直播课抽屉
            videoId: '', ////当前播放的视频id
            curVideoItem: {}, //当前播放的视频
            wordList: [], //ai转换的文字数据
            wordListCopyForEdit: [], // ai转换的文字数据
            wordListCopyForOrigin: [], // ai转换的文字数据
            wordLoading: true, //文字loading
            startTime: '', //当前视频开始时间，用于切片视频与文字对应的计算
            showMask: false, //是否显示视频蒙层，仅在第一次播放前显示
            sumOfExampleVideo: 0, //示例视频个数
            searchDone: false, //是否搜索完成，显示搜索结果
            timer: null, //定时器
            videoReady: false, //播放器是否初始化完成
            scrollDom: null,
            playerForAliplayer: null, // 阿里云播放器示例
            // 当前播放的地址：
            videoSrcForCurrent: '', // 阿里云播放器当前需要播放的地址
            videoRecordStreamUrl: '', // 关于切片视频没有出来的时候需要播放的HLS的流地址
            whoHaveToEditForWord: '', // 关于文字编辑，谁需要编辑
            lastStrForDelete: '', // 针对文字编辑，点击回车的时候，上一次的那一句话
            theIdForLastTargetForClick: '',
            theInnerHTMLForLastTargetForClickOrigin: '',
            haveTheOnInputForWord: false,
            videoCaptionsFile: ''
        }
    },
    watch: {
        videoList: {
            handler(val) {
                if (val && val.length) {
                    this.videoId = val[0].videoId
                    this.curVideoItem = val[0]
                    // 获取当前视频的开始时间
                    this.startTime = val[0].startTime * 1000 || 0
                    // 示例视频数量
                    this.sumOfExampleVideo = val.findIndex(item => item.videoType == 3)
                }
            }
        },
        videoId: {
            async handler(val) {
                if (val) {
                    this.wordLoading = true
                    // 如果转码完成，初始化播放器
                    // if (this.curVideoItem.isTranscode) {
                    //     this.initPlayer()
                    // }
                    // 切换videoId,首先结束轮询
                    clearInterval(this.timer)
                    this.timer = null
                    this.getText()
                    setTimeout(() => {
                        this.getVideoSrcForCurrent(this.getVideoCaptionsFile)
                    }, 300)
                }
            },
            immediate: true
        },
        isTranscode: {
            handler(val) {
                // 如果转码状态发生变化，且成功，初始化播放器并展示蒙层
                if (val) {
                    this.showMask = true
                    // this.initPlayer()
                }
            }
        }
    },
    async mounted() {
        const self = this
        const showDrawer = this.$route.query.show
        if (showDrawer == 1) {
            this.drawer = true
        }
        // 获取切片还没有出来时的HLS:
        await this.getVideoRecordInfo()
        // 初始化阿里云播放器：
        this.initAliplayerVideo()

        bus.$on('operationForEditBtnFlagChangeForWord', flag => {
            if (!flag) {
                // 去除选中：
                this.whoHaveToEditForWord = ''

                // 这里仅仅这是恢复页面的展示：
                // contenteditable所编辑的内容无法自动清除，所以在点击的时候需要拿到当时的内容，在点击的时候需要恢复至当时的内容：
                if (this.theIdForLastTargetForClick) {
                    const domForTheIdForLastTargetForClick = document.querySelector(`#${this.theIdForLastTargetForClick}`)
                    domForTheIdForLastTargetForClick && domForTheIdForLastTargetForClick.classList.remove('container_can_edit_for_inner_word')
                    domForTheIdForLastTargetForClick && (domForTheIdForLastTargetForClick.setAttribute('contenteditable', false))
                    if (this.theInnerHTMLForLastTargetForClickOrigin && domForTheIdForLastTargetForClick) {
                        domForTheIdForLastTargetForClick.innerHTML = this.theInnerHTMLForLastTargetForClickOrigin
                    }
                }

                // 恢复文字：
                const newWordList = JSON.parse(JSON.stringify(this.wordListCopyForOrigin))
                this.wordList = JSON.parse(JSON.stringify(newWordList))
                this.wordListCopyForEdit = JSON.parse(JSON.stringify(newWordList))
            }
        })
        this.$on('hook:beforeDestroy', () => {
            bus.$off('operationForEditBtnFlagChangeForWord')
        })

        const aliPlayerDom = document.querySelector('#J_prismPlayer') // .prism-cc-btn
        aliPlayerDom.onclick = function (e) {
            console.log(3063, e.target.className, e.target.className.includes('prism-cc-btn'))
            if (e.target.className.includes('prism-cc-btn')) {
                self.toggleSubtitleHandle(self.isPlayerSubtitleOpen ? 'off' : 'on')
            }
        }
    },
    beforeDestroy() {
        this.timer && clearInterval(this.timer)
    },
    methods: {
        ...mapMutations('common', {
            mutationForSetEditBtnListShow: 'mutationForSetEditBtnListShow'
        }),
        // 初始化播放器
        // 播放器文档(可能不是最新的)：https://note.youdao.com/ynoteshare1/index.html?id=107720ddbc8f27030cca213d2ac796f0&type=note
        // 播放器相关问题找卢燕伟
        // initPlayer() {
            // $("#container").Ableplayer({
            //     "id": this.videoId, //视频ID 必填  id和src  二选一
            //     //,"src" : "http://video.zhihuishu.com/video.zhihuishu.com/testzhs/createcourse/course_second/201607/a4eaa3f18772400db2b0507b8e4acf95_500.mp4"
            //     "autostart": false, //是否自动播放    默认true
            //     "isIncreaseRate": true, //是否开启3倍速播放
            //     "image": this.curVideoItem.imageUrl, //封面图片 默认空
            //     //,"mp3Mode":true //mp3模式    默认false
            //     //,"videoTitleTxt":"测试title"
            //     "control": {//播放器按钮显示控制
            //         nextBtn: false, //下一节 默认false
            //         rateBtn: true, //速率 默认显示
            //         fullBtn: true, //全屏 默认显示
            //         volumeBtn: true, //音量 默认显示
            //         trackBtn: true, //字幕 默认显示
            //         definiBtn: true, //清晰度 默认显示
            //         bigPlayerBtn: true, //大播放按钮 默认显示
            //         playTime: true, //进度时间 默认显示
            //         errorBar: true, //错误提示 默认显示
            //     }
            // }, {
            //     onReady: function () {//初始化完成
            //         console.log("onReady");
            //         // setTimeout(() => {
            //         //     this.videoDom = document.querySelector('#vjs_container_html5_api')
            //         //     this.initEvent()
            //         // }, 100)
            //         // 播放器初始化完成显示播放按钮
            //         this.videoReady = true
            //     }.bind(this),
            //     onComplete: function () {//播放完成
            //         console.log("onComplete");
            //     },
            //     onPause: function () {//暂停
            //         // this.showMask = true
            //         console.log("onPause");
            //     }.bind(this),
            //     onPlay: function () {//播放
            //         this.showMask = false
            //         console.log("onPlay");
            //     }.bind(this),
            //     onExitFullScreen: function () {//退出全屏
            //         console.log("onExitFullScreen");
            //     },
            //     onTime: function (time) {//播放进度改变
            //         // console.log("onTime:" + time);
            //         let currentTime = Math.floor(time * 1000)
            //         this.addClass(currentTime + this.startTime)
            //     }.bind(this),
            //     playbackRate: function (rate) {
            //         console.log(rate); //播放速率
            //     },
            //     playerNext: function () {
            //         console.log("next"); //下一节
            //     }
            // });
        // },
        // 输入框失去焦点，添加输入提示
        blurInput(e) {
            e.target.parentNode.classList.remove('focus')
            e.target.setAttribute('placeholder', '搜索')
        },
        // 输入框获取焦点隐藏输入提示
        focusInput(e) {
            e.target.parentNode.classList.add('focus')
            e.target.setAttribute('placeholder', '')
        },
        // 初始化事件绑定
        initEvent() {
            this.addEventToVideo()
        },
        // 跳到上一个
        toBefore() {
            this.curChooseIndex -= 1
            this.curChooseIndex = 0 > this.curChooseIndex ? this.searchDomList.length - 1 : this.curChooseIndex
            let cid = this.searchDomList[this.curChooseIndex].wb
            this.scrollTo(cid)
            // this.videoDom.currentTime = (cid - this.startTime) / 1000
            // ablePlayerX("container").seek((cid - this.startTime) / 1000)
            this.playerForAliplayer.seek((cid - this.startTime) / 1000)

        },
        // 跳到下一个
        toNext() {
            this.curChooseIndex += 1
            this.curChooseIndex = this.searchDomList.length > this.curChooseIndex ? this.curChooseIndex : 0
            let cid = this.searchDomList[this.curChooseIndex].wb
            this.scrollTo(cid)
            // this.videoDom.currentTime = (cid - this.startTime) / 1000
            // ablePlayerX("container").seek((cid - this.startTime) / 1000)
            this.playerForAliplayer.seek((cid - this.startTime) / 1000)

        },
        // 清除输入框内容，并清除搜索结果
        clearSearchResult() {
            this.searchText = ''
            this.searchDone = false
            this.clearClass()
        },
        // 清除当前搜索结果
        clearClass() {
            if (!this.clearClassList.length) return
            this.clearClassList.forEach(item => {
                let dom = document.querySelector(`[cid='${item.wb}']`)
                dom.innerHTML = item.w
            })
            this.clearClassList = []
            this.searchDomList = []
            this.curChooseIndex = 0
        },
        // 给输入框添加防抖
        handleInput: debounce(function () {
            this.handleSearch()
        }, 1000),
        // 搜索事件
        handleSearch() {
            // 清除当前搜索结果
            this.clearClass()

            if (!this.searchText || this.searchText.length == 0) {
                this.clearSearchResult()
                return
            }

            let arr = []
            console.log(this.searchText, 'this.searchText')
            console.log(this.allWordTime, 'this.allWordTime')
            // 单个文字时直接过滤，节省性能
            if (this.searchText.length == 1) {
                arr = this.allWordTime.filter((item, index) => {
                    return item.w.indexOf(this.searchText) > -1
                })
            } else {
                // 多个文字时执行匹配逻辑
                let searchTextArr = this.searchText.split('')
                // 获取文字长度，用于添加标记
                let length = searchTextArr.length
                // 循环查找
                let arrLength = this.allWordTime.length
                for (let i = 0; i < arrLength; i++) {
                    let item = this.allWordTime[i]
                    // 待查找的文字，先查找第一个字
                    let targetText = ''
                    // 需要从哪里查找
                    let templateText = item.w
                    // 偏移量
                    let offset = 0
                    // 先查找前两个字
                    targetText = searchTextArr[0] + searchTextArr[1]
                    if (!this.compareText(targetText, templateText)) {
                        // 如果第一个字匹配不上，直接跳过这次循环
                        continue
                    } else {
                        // 如果匹配上了，直接匹配剩下所有的字
                        offset = templateText.indexOf(targetText)
                        // 当前待匹配文字的长度
                        let curlength = templateText.length
                        // 当前元素的下标，累加templateText时需要递增
                        let curIndex = i + 1
                        // 文字长度+偏移量，用于计算templateText
                        let lengthWithOffset = length + offset
                        // 当前待匹配文字的长度不够时
                        while (curlength < lengthWithOffset && this.allWordTime[curIndex]) {
                            // 获取到下个元素的长度，累加
                            let alength = this.allWordTime[curIndex].w.length
                            if (alength < lengthWithOffset - curlength) {
                                // 累加文字
                                templateText += this.allWordTime[curIndex].w
                                // 累加长度
                                curlength += alength
                            } else {
                                // 累加文字
                                templateText += this.allWordTime[curIndex].w.substr(0, lengthWithOffset - curlength)
                                // 累加长度
                                curlength = lengthWithOffset
                            }

                            // 累加下标
                            curIndex += 1
                        }
                        // 匹配不通过，则跳过此次循环
                        if (!this.compareText(this.searchText, templateText)) {
                            continue
                        }
                    }
                    // 记录待标记文字的长度和偏移量
                    item.size = length
                    item.offset = offset

                    arr.push(item)
                }
            }
            // 循环标记
            arr.forEach(item => {
                this.addClassAccuracy(item)
            })
            this.searchDomList = arr
            this.curChooseIndex = 0
            // this.addClass(arr[0].cid)
            // 搜索完成
            this.searchDone = true
            if (arr.length) {
                this.scrollTo(arr[0].wb)
                // this.videoDom.currentTime = (arr[0].wb - this.startTime) / 1000 || 0
                // ablePlayerX("container").seek((arr[0].wb - this.startTime) / 1000 || 0)
                this.playerForAliplayer.seek((arr[0].wb - this.startTime) / 1000 || 0)
            }

        },
        compareText(targetText, templateText) {
            return templateText.indexOf(targetText) > -1
        },
        // 搜索添加类名，通过cid精准添加
        addClassAccuracy({ wb, size, offset, w }) {

            //offset: 偏移量，指cid对应dom内文字偏移量
            // 获取到dom
            let dom = document.querySelector(`[cid='${wb}']`)

            if (!dom) return

            // 单文字处理
            if (!size) {
                this.clearClassList.push({
                    wb,
                    w
                })
                let html = dom.innerHTML.replace(this.searchText, `<span class="search-select-back-color">${this.searchText}</span>`)
                dom.innerHTML = html
                return
            }
            let text = dom.innerText
            // 判断需要添加的文本是否在一个span内
            if (text.length >= size + offset) {
                // 当在一个span内，直接替换即可
                // 记录当前标记的元素，便于清除标记
                this.clearClassList.push({
                    wb,
                    w
                })
                // 添加标记
                let html = dom.innerHTML.replace(this.searchText, `<span class="search-select-back-color">${this.searchText}</span>`)
                dom.innerHTML = html
            } else {
                // 不在一个span内，需根据size计算需要标记的span
                // 先将当前dom里的文字添加标记
                // 根据offset获取到需要标记的文字
                let partText = text.substr(offset)
                // 记录已经标记的长度
                let alreadyLength = partText.length
                // 添加标记
                let html = dom.innerHTML.replace(partText, `<span class="search-select-back-color">${partText}</span>`)
                dom.innerHTML = html
                // 记录当前标记的元素，便于清除标记
                this.clearClassList.push({
                    wb,
                    w
                })
                // 计算剩余未标记的长度
                let leftLength = size - alreadyLength
                // 逐个标记
                while (leftLength > 0) {
                    // 获取到下一个dom
                    dom = dom.nextSibling
                    // 获取到当前dom的文字，判断是否需要完全标记该dom
                    let curlength = dom.innerText.length
                    let curText = ''
                    if (curlength < leftLength) {
                        curText = dom.innerText
                    } else {
                        // 部分标记时，获取到需要标记的文本
                        curText = dom.innerText.substr(0, leftLength)
                    }
                    // 记录当前标记的元素，便于清除标记
                    let curCid = dom.getAttribute('cid')
                    let curWd = dom.innerText
                    this.clearClassList.push({
                        wb: curCid,
                        w: curWd
                    })
                    // 添加标记
                    let html = dom.innerHTML.replace(curText, `<span class="search-select-back-color">${curText}</span>`)
                    dom.innerHTML = html

                    leftLength -= curlength
                }
                // dom.classList.add('content-highlight')
            }
        },
        // 给播放器添加事件，实现文字跟随视频的效果
        addEventToVideo() {
            // this.videoDom.addEventListener('timeupdate', event => {
            //     let currentTime = Math.floor(this.videoDom.currentTime * 1000)
            //     this.addClass(currentTime + this.startTime)
            // })
        },
        // 添加点击事件，实现点击词语将视频跳转到当前文字所对应的时间
        addEventToText() {
            this.$nextTick(() => {
                let dom = document.querySelector('#text-content')
                dom.addEventListener('click', function (event) {
                    let target = event.target
                    let cid
                    let paragraphWhere
                    let sentenceWhere
                    if (target.className == 'word') {
                        cid = target.getAttribute('cid')
                        paragraphWhere = target.getAttribute('paragraphWhere')
                        sentenceWhere = target.getAttribute('sentenceWhere')
                        // ablePlayerX("container").seek((cid - this.startTime) / 1000)
                        // console.log(target, 'target_addEventToText')
                        // console.log(cid, this.startTime, 'cid_this_startTime_addEventToText')
                        this.playerForAliplayer.seek((cid - this.startTime) / 1000)
                        // 允许编辑的时候才可以编辑：
                        if (this.editBtnListShow) {
                            this.disposeTheClassAndContenteditable(target)
                            this.whoHaveToEditForWord = cid
                            this.addEventListenerForContenteditableHtmlElement({
                                cid,
                                paragraphWhere,
                                sentenceWhere
                            })
                        }
                    } else if ((target.parentNode.className == 'word') || target.parentNode.className.includes('word content-highlight')) {
                        cid = target.parentNode.getAttribute('cid')
                        paragraphWhere = target.parentNode.getAttribute('paragraphWhere')
                        sentenceWhere = target.parentNode.getAttribute('sentenceWhere')
                        // ablePlayerX("container").seek((cid - this.startTime) / 1000)
                        // console.log(target.parentNode, 'target_parentNode_addEventToText')
                        // console.log(cid, this.startTime, 'cid_this_startTime_addEventToText')
                        // console.log((cid - this.startTime) / 1000, 'difference_addEventToText')
                        // console.log(this.allWordTime, 'this.allWordTime_addEventToText')
                        // this.playerForAliplayer.newSeek((cid - this.startTime) / 1000)
                        this.playerForAliplayer.seek((cid - this.startTime) / 1000)
                        // 允许编辑的时候才可以编辑：
                        if (this.editBtnListShow) {
                            this.disposeTheClassAndContenteditable(target.parentNode)
                            this.whoHaveToEditForWord = cid
                            this.addEventListenerForContenteditableHtmlElement({
                                cid,
                                paragraphWhere,
                                sentenceWhere
                            })
                        }
                    }
                    // this.videoDom.currentTime = (cid - this.startTime) / 1000
                }.bind(this), false)
            })
        },
        disposeTheClassAndContenteditable (who) {
            if (this.theIdForLastTargetForClick) {
                const domForTheIdForLastTargetForClick = document.querySelector(`#${this.theIdForLastTargetForClick}`)
                domForTheIdForLastTargetForClick && domForTheIdForLastTargetForClick.classList.remove('container_can_edit_for_inner_word')
                domForTheIdForLastTargetForClick && (domForTheIdForLastTargetForClick.setAttribute('contenteditable', false))
            }
            who.classList.add('container_can_edit_for_inner_word')
            who.setAttribute('contenteditable', true)
            this.theIdForLastTargetForClick = who.id
            const newDom = document.querySelector(`#${this.theIdForLastTargetForClick}`)
            newDom && (this.theInnerHTMLForLastTargetForClickOrigin = newDom.innerHTML)
        },
        // 获取视频提取的文字
        async getText() {
            this.allWordTime = []
            const params = {
                videoId: this.curVideoItem.videoId,
                videoType: this.curVideoItem.videoType,
                mixVideoId: this.curVideoItem.mixVideoId,
                startTime: this.curVideoItem.startTime,
                endTime: this.curVideoItem.endTime,
            }
            // const params = {
            //     videoId: '38315139',
            //     videoType: 1,
            //     mixVideoId: '38315139',
            //     // startTime: this.curVideoItem.startTime,
            //     // endTime: this.curVideoItem.endTime,
            // }
            const { data } = await this.$http.getAIText(params)
            this.wordList = data.rt
            this.wordListCopyForEdit = JSON.parse(JSON.stringify(this.wordList))
            this.wordListCopyForOrigin = JSON.parse(JSON.stringify(this.wordList))
            this.wordLoading = false

            if (this.wordList && this.wordList.length) {
                // 有数据结束轮询
                clearInterval(this.timer)
                this.timer = null
                this.$nextTick(() => {
                    this.scrollDom = this.$refs['textScrollbar'].wrap//document.querySelector('.el-scrollbar__wrap')
                    this.addEventToText()
                    this.addClass(this.currentDomCid)
                })
                // 记录所有词
                data.rt.forEach(item => {
                    item.contentList.forEach(word => {
                        this.allWordTime.push(word)
                    })
                })
            } else if (!this.timer) {
                // 没数据轮询获取
                this.pollingGet()
            }
        },
        // 轮询获取提取文字
        pollingGet() {
            this.timer = setInterval(() => {
                this.getText()
            }, 10000)
        },
        // 根据视频播放时间，给对应的文字添加背景色
        addClass(cid) {
            // 找到第一个时间小于当前时间的词
            // let indexForFirst = 0
            let first = this.allWordTime.find((item, index) => {
                // indexForFirst = index
                return item.we > cid
            })
            if (!first || first.wb == this.currentDomCid) return
            // const next = this.allWordTime.length >= indexForFirst + 1 ? this.allWordTime[indexForFirst + 1] : first
            // if ((first.wb < cid) && (next.wb > cid)) first = next
            // this.scrollTo(first.wb)
            this.currentDom && this.currentDom.classList.remove('content-highlight')
            let dom = document.querySelector(`[cid='${first.wb}']`)
            dom && dom.classList.add('content-highlight')
            // 记录当前高亮词
            this.currentDom = dom
            this.currentDomCid = dom.attributes.cid.value
            this.scrollTo(first.wb)
            return

            // 由于该时间不一定存在于词语数组中，可能是两个词中间的某一刻，故需循环查找最接近的一个词
            for (let i = 0; i < this.allWordTime.length - 1; i++) {
                if (cid >= this.allWordTime[i].wb && cid < this.allWordTime[i + 1].wb) {
                    // 首先清除上一个词的背景
                    this.currentDom && this.currentDom.classList.remove('content-highlight')
                    let dom = document.querySelector(`[cid='${this.allWordTime[i].wb}']`)
                    dom.classList.add('content-highlight')
                    // 记录当前高亮词
                    this.currentDom = dom
                    break
                }
            }
        },
        // 讲文字滚动到视图内
        scrollTo(cid) {
            let dom = document.querySelector(`[cid='${cid}']`)
            if (dom && this.needToScroll(dom)) {
                if (!this.scrollDom) return
                this.scrollDom.scrollTo({
                    top: dom.offsetTop - 60,
                    behavior: 'smooth'
                })
            }
            // return
            // if (!dom && !this.isInViewPortOfOne(dom)) {
            //     dom.scrollIntoView({ block: 'center', behavior: 'smooth' })
            // }
        },
        // 找到子元素在父元素中的相对位置
        getElementTop(element) {
            var el = (typeof element == "string") ? document.getElementById(element) : element;

            if (el.parentNode === null || el.style.display == 'none') {
                return false;
            }

            return el.offsetTop - el.parentNode.offsetTop;
        },
        // 判断元素是否在视图内
        isInViewPortOfOne(el) {
            const viewHeight = window.innerHeight || document.documentElement.clientHeight;
            const { top, bottom } = el.getBoundingClientRect();
            return (top >= 0 && bottom <= viewHeight);
        },
        // 判断是否需要滚动
        needToScroll(el) {
            const offsetParent = this.scrollDom
            const height = document.querySelector('.text-box').clientHeight
            return el.offsetTop - offsetParent.scrollTop < 50 || el.offsetTop - offsetParent.scrollTop > height - 130
        },
        // 格式化时间，毫秒转为分秒  00:00
        formatTime(millisecond) {
            let seconds = Math.round(millisecond / 1000);
            let result = [];
            let count = 1;
            while (count >= 0) {
                let current = Math.floor(seconds / (60 ** count));
                result.push(current);
                seconds -= current * (60 ** count);
                --count;
            }
            return result.map(item => item <= 9 ? `0${item}` : item).join(":")
        },


        // 触发上传
        handleUpload() {
            this.$emit('handleUpload')
        },
        async cutByMyself(n) {
          // 转码中禁止手动剪辑
          if (!this.isTranscode) {
            this.$message.warning('视频转码、审核中...请稍后再试！')
            return
          }
          // 获取融合后视频
          let videoId = this.videoList[0].mixVideoId
          this.handlePause()
          if(n==1){
            this.$emit('cutByMySecond',videoId,this.isSample)
          }else{
            this.$emit('cutByMyself', videoId, this.isSample)
          }

        },
        // 切换视频
        switchVideo(item) {
            this.showMask = true
            this.videoReady = false
            this.videoId = item.videoId
            this.curVideoItem = item
            // 获取当前视频的开始时间
            this.startTime = item.startTime * 1000 || 0
            this.clearSearchResult()
        },
        async getVideoCaptionsFile () {
            const { videoId, videoType, mixVideoId, startTime, endTime } = this.curVideoItem
            const params = {
                videoId,
                videoType,
                mixVideoId,
                startTime,
                endTime,
                liveId: this.liveId
            }
            const { data: result } = await this.$http.getVideoCaptionsFile(params)
            if (result.code !== 200) return
            const { rt: resultReally } = result
            if (resultReally.resultStatus !== 1) return
            this.videoCaptionsFile = resultReally.captionsFileUrl
        },
        getVideoUrl(videoID) {
            return new Promise(async (resolve, reject) => {
                let result = await this.$http.getVideoByIdNew({ videoID })
                result = result.data
                if (result.errorCode !== 0) return reject(result.errorMsg)
                const videoObj = (result.result.lines || []).find((item) => item.lineUrl) || {}
                if (!videoObj) return reject('课程结束1小时后生成回放，请稍后重试！')
                resolve(videoObj.lineUrl || '')
            })
        },
        // 设置播放器的字幕：
        setTextTracksForAliplayer () {
            if (!this.playerForAliplayer) return
            this.playerForAliplayer.setTextTracks(
                [
                    {
                        kind: 'subtitles', // kind：vtt类型，取值包括subtitles和captions。
                        label: '中文', // label：用于显示的字幕名称。
                        src: this.videoCaptionsFile, // src：字幕地址，请允许跨域访问。
                        // src: 'https://file.zhihuishu.com/zhs_yufa_150820/simple-class/simple-class/video/revord/202302/6f58d0d020df495c9f1167142684f160.vtt',
                        srclang: 'zh-cn' // srclang：字幕语言。
                    }
                ]
            )
            const videoTextTracks = Array.from(this.playerForAliplayer.tag.textTracks) || []
            videoTextTracks.forEach(item => {
                item.mode = 'showing'
            })
        },
        // 获取回放地址，并处理阿里云播放器：
        async getVideoSrcForCurrent (cb) {
            try {
                if (this.playerForAliplayer) {
                    this.playerForAliplayer.dispose()
                    this.playerForAliplayer = null
                }
                this.videoSrcForCurrent = await this.getVideoUrl(this.videoId)
                if (this.videoSrcForCurrent) {
                    this.initAliplayerVideo()
                    this.playerForAliplayer.loadByUrl(this.videoSrcForCurrent)
                    // this.playerForAliplayer.loadByUrl('https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4') // TODO:本地开发使用的视频资源
                    this.playerForAliplayer.setCover(this.curVideoItem.imageUrl)
                    cb && await cb()
                    this.setTextTracksForAliplayer()
                }
            } catch (err) {
                console.log(err)
                // setTimeout(() => {
                //     this.getVideoSrcForCurrent(cb)
                // }, 2000)
            }
        },
        // 播放
        handlePlay() {
            // this.videoDom && this.videoDom.play()
            // ablePlayerX("container").play()
        },
        // 暂停
        handlePause() {
            // this.videoDom && this.videoDom.pause()
            // ablePlayerX("container").pause()
        },
        switchLive(item) {
          this.$store.commit("select/edit", item.num)
            // this.$router.push({
            //     name: 'playback',
            //     query: {
            //         id: item.liveId,
            //         show: 1,
            //     }
            // })
            const url = `https://tp.zhihuishu.com/#/playback?role=0&id=${item.liveId}&show=1`
            window.open(url, "_blank")
            location.reload()
        },
        // 获取切片还没有出来时HLS地址：
        async getVideoRecordInfo () {
            try {
                const { id: liveId } = this.$route.query
                if (!liveId) return
                const { data: result } = await this.$http.getVideoRecordInfo({ liveId })
                if (result.code !== 200) return this.$message.error(result.msg)
                const haveVideoRecordStreamUrl = Object.prototype.hasOwnProperty.call(result.rt || {}, 'videoRecordStreamUrl')
                if (haveVideoRecordStreamUrl) {
                    this.videoRecordStreamUrl = result.rt.videoRecordStreamUrl
                }
            } catch (err) {
                console.log(err)
            }
        },
        // 初始化阿里播放器：
        initAliplayerVideo () {
            const props = {
                id: 'J_prismPlayer',
                width: '100%',
                height: '450px',
                autoplay: false,
                preload: true,
                controlBarVisibility: 'always',
                useH5Prism: true,
                playsinline: true,
                keyShortCuts: true,
                isLive: false,
                language: 'zh-cn',
                // source: 'https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4', // 播放地址
                // source: 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8', // 播放地址
                // cover: 'https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202209/295eedc2dcb54965b1fe17e64b352d95.png', // 封面地址
                source: '',
                cover: '',
                components: [],
                skinLayout: [
                    // { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                    { name: "bigPlayButton", align: "cc" },
                    { name: "H5Loading", align: "cc" },
                    // { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                    { name: "infoDisplay", align: "cc" },
                    { name: "tooltip", align: "blabs", x: 0, y: 15 },
                    {
                        name: "controlBar",
                        align: "blabs",
                        x: 0,
                        y: 0,
                        children: [
                            { name: "progress", align: "blabs", x: 0, y: 44 },
                            { name: "playButton", align: "tl", x: 15, y: 14 },
                            { name: "timeDisplay", align: "tl", x: 10, y: 9 },
                            { name: "fullScreenButton", align: "tr", x: 10, y: 14 },
                            { name: "subtitle", align: "tr", x:15, y:14 },
                            { name: "setting", align: "tr", x: 15, y: 14},
                            { name: "volume", align: "tr", x: 12, y: 12 }
                        ]
                    }
                ]
            }

            // init：
            this.playerForAliplayer = new Aliplayer(props, function (playerForAliplayer) {
                console.log(playerForAliplayer, 'playerForAliplayer in new Aliplayer')
                playerForAliplayer.seek = function (time) {
                    playerForAliplayer.tag.currentTime = time
                }

            })
            this.playerForAliplayer.on('ready', () => {
                this.toggleSubtitleHandle('off')
            })

            // canplay：
            const canplayHandle = () => {
                console.log('canplayHandle')
                // console.log(this.playerForAliplayer.getDuration(), 'playerForAliplayer.getDuration')
                this.curVideoItem.isTranscode = true
            }
            this.playerForAliplayer.on('canplay', canplayHandle)

            // play：
            const playHandle = () => {
                console.log('playHandle')
                this.showMask = false
            }
            this.playerForAliplayer.on('play', playHandle)

            // timeupdate：
            const timeupdateHandle = (e) => {
                // let currentTime = Math.floor(this.playerForAliplayer.getCurrentTime()) * 1000
                // console.log(this.playerForAliplayer.getCurrentTime(), 'this.playerForAliplayer.getCurrentTime()')
                let currentTime = this.playerForAliplayer.getCurrentTime() * 1000
                this.addClass(currentTime + this.startTime)
            }
            this.playerForAliplayer.on('timeupdate', timeupdateHandle)

            // error：
            const errorHandle = (error) => {
                console.log(error, 'errorHandle')
                // this.playerForAliplayer.pause()
                // this.videoRecordStreamUrl = ''
                // this.curVideoItem.isTranscode = false
            }
            this.playerForAliplayer.on('error', errorHandle)
            if (!(this.videoList || []).length) {
                // 特殊判断，如果切片没有出来，则需要播放HLS：
                if (this.videoRecordStreamUrl) {
                    this.playerForAliplayer.loadByUrl(this.videoRecordStreamUrl)
                }
            } else {
                this.videoRecordStreamUrl = ''
            }
        },
        // 字幕开关
        toggleSubtitleHandle(toggle) {
            try {
                if (toggle === 'off') {
                    this.isPlayerSubtitleOpen = false
                    this.playerForAliplayer._ccService.close();
                    console.log(3066, '关闭', this.playerForAliplayer._ccService.getCurrentSubtitle())  // off
                    this.playerForAliplayer._ccService.updateUI();
                } else {
                    this.isPlayerSubtitleOpen = true
                    const result = this.playerForAliplayer._ccService.open()
                    console.log(3067, '开启', this.playerForAliplayer._ccService.getCurrentSubtitle())
                    const subtitleDisplayDom = document.querySelector('.prism-text-overlay>div')
                    subtitleDisplayDom.innerHTML=`<div class="subtitle-fill">${this.currentDom.innerHTML}</div>` // 主动塞入字幕
                    this.playerForAliplayer._ccService.updateUI(result.language);
                }
            } catch (error) {
            }
        },
        getCaretIndex(element) {
            let position = 0;
            const isSupported = typeof window.getSelection !== "undefined";
            if (isSupported) {
                const selection = window.getSelection();
                // Check if there is a selection (i.e. cursor in place)
                if (selection.rangeCount !== 0) {
                    // Store the original range
                    const range = window.getSelection().getRangeAt(0);
                    // Clone the range
                    const preCaretRange = range.cloneRange();
                    // Select all textual contents from the contenteditable element
                    preCaretRange.selectNodeContents(element);
                    // And set the range end to the original clicked position
                    preCaretRange.setEnd(range.endContainer, range.endOffset);
                    // Return the text length from contenteditable start to the range end
                    position = preCaretRange.toString().length;
                }
            }
            return position;
        },
        // 监听contenteditable变化：
        addEventListenerForContenteditableHtmlElement (paramsForAddEventListenerForContenteditableHtmlElement) {
            const { paragraphWhere, sentenceWhere } = paramsForAddEventListenerForContenteditableHtmlElement
            this.$nextTick(() => {
                const contenteditableHtmlElement = document.querySelector(`#word_paragraphWhere${paragraphWhere}_sentenceWhere${sentenceWhere}`)
                if (!contenteditableHtmlElement) return
                contenteditableHtmlElement.focus()
                const _that = this
                contenteditableHtmlElement.oninput = function () {
                    _that.haveTheOnInputForWord = true
                }
                contenteditableHtmlElement.onkeydown = function (e) {
                    const dom = e.target
                    const fatherItemId = dom.getAttribute('father-item-id') || ''
                    const selfItemId = dom.getAttribute('self-item-id') || ''
                    const fatherItem = _that.wordList.find(item => item.beginTime === Number(fatherItemId)) || {}
                    const selfItem = (fatherItem.contentList || []).find(item => item.wb === Number(selfItemId)) || {}
                    const str = e.target.innerText
                    // 获取光标的位置：
                    const cursorPosition = _that.getCaretIndex(contenteditableHtmlElement)
                    // 是当前段中的第几句（索引）：
                    const partitionIndexForContentList = fatherItem.contentList.findIndex(item => item.wb === selfItem.wb)
                    if (e.code === 'Enter') {
                        if (!partitionIndexForContentList || (partitionIndexForContentList === fatherItem.contentList.length - 1)) {
                            if ((str.length === cursorPosition) || (cursorPosition === 0)) {
                                return false
                            }
                        }
                    } else if (e.code === 'Backspace') {
                        if (!cursorPosition && !partitionIndexForContentList) {
                            _that.lastStrForDelete = str
                        }
                    }
                }
                contenteditableHtmlElement.onkeyup = function (e) {
                    const dom = e.target
                    const fatherItemId = dom.getAttribute('father-item-id') || ''
                    const selfItemId = dom.getAttribute('self-item-id') || ''
                    const fatherItem = _that.wordList.find(item => item.beginTime === Number(fatherItemId)) || {}
                    const selfItem = (fatherItem.contentList || []).find(item => item.wb === Number(selfItemId)) || {}
                    const str = e.target.innerText
                    // 是第几段（索引）：
                    const partitionIndexForWordListCopy = _that.wordListCopyForEdit.findIndex(item => item.beginTime === fatherItem.beginTime)
                    // 是当前段中的第几句（索引）：
                    const partitionIndexForContentList = fatherItem.contentList.findIndex(item => item.wb === selfItem.wb)
                    // 最新句：
                    const newSelfItem = _that.wordListCopyForEdit[partitionIndexForWordListCopy].contentList[partitionIndexForContentList] || {}
                    const eCode = e.code
                    switch (eCode) {
                        // 拆分成两段：
                        case 'Enter':
                            console.log('Enter_Type')
                            for (let i = 0, len = str.length; i < len; i++) {
                                if (str.charCodeAt(i) == 10) {
                                    if (i === str.length - 1) return
                                    // 需要把文字从哪个地方隔开：
                                    const indexForSplit = i - 1
                                    const wordInfoListForBefore = []
                                    const wordInfoListForAfter = []
                                    ;(newSelfItem.wordInfoList || []).forEach((item, index) => {
                                        // 注意：回车的时候'\n'不能要：
                                        if (item.w !== '\n') {
                                            if (index <= indexForSplit) {
                                                wordInfoListForBefore.push(item)
                                            } else if (index > indexForSplit) {
                                                wordInfoListForAfter.push(item)
                                            }
                                        }
                                    })
                                    // 先把句分开：
                                    const partitionInnerNewList = [wordInfoListForBefore, wordInfoListForAfter].map(item => {
                                        if (item.length) {
                                            return {
                                                w: item.map(v => v.w).join(''),
                                                wb: item[0].wb,
                                                we: item[item.length - 1].we,
                                                wordInfoList: item
                                            }
                                        }
                                    }).filter(item => item)
                                    // 先把句替换掉：
                                    if (partitionInnerNewList.length === 1) {
                                        fatherItem.contentList.splice(partitionIndexForContentList, 1, partitionInnerNewList[0])
                                    }
                                    if (partitionInnerNewList.length === 2) {
                                        fatherItem.contentList.splice(partitionIndexForContentList, 1, partitionInnerNewList[0], partitionInnerNewList[1])
                                    }
                                    // 生成新的段落：
                                    const { username, beginTime, endTime } = fatherItem
                                    const beforeItem = {
                                        contentList: [],
                                        username,
                                        beginTime,
                                        endTime: beginTime
                                    }
                                    const afterItem = {
                                        contentList: [],
                                        username,
                                        beginTime: endTime,
                                        endTime
                                    }
                                    ;(fatherItem.contentList || []).forEach((item, index) => {
                                        if (!wordInfoListForBefore.length) {
                                            if (index < partitionIndexForContentList) {
                                                if (index = partitionIndexForContentList - 1) {
                                                    beforeItem['endTime'] = item.wb
                                                }
                                                beforeItem['contentList'].push(item)
                                            } else if (index >= partitionIndexForContentList) {
                                                if (index === partitionIndexForContentList + 1) {
                                                    afterItem['beginTime'] = item.wb
                                                }
                                                afterItem['contentList'].push(item)
                                            }
                                        } else {
                                            if (index <= partitionIndexForContentList) {
                                                if (index = partitionIndexForContentList) {
                                                    beforeItem['endTime'] = item.wb
                                                }
                                                beforeItem['contentList'].push(item)
                                            } else if (index > partitionIndexForContentList) {
                                                if (index === partitionIndexForContentList + 1) {
                                                    afterItem['beginTime'] = item.wb
                                                }
                                                afterItem['contentList'].push(item)
                                            }
                                        }
                                    })
                                    // 更新列表：
                                    const newWordList = JSON.parse(JSON.stringify(_that.wordListCopyForEdit))
                                    const beforeItemBeforeItemLength = beforeItem.contentList.length
                                    const afterItemBeforeItemLength = afterItem.contentList.length
                                    if (beforeItemBeforeItemLength && afterItemBeforeItemLength) {
                                        newWordList.splice(partitionIndexForWordListCopy, 1, beforeItem, afterItem)
                                    }
                                    else if (beforeItemBeforeItemLength&& !afterItemBeforeItemLength) {
                                        newWordList.splice(partitionIndexForWordListCopy, 1, beforeItem)
                                    }
                                    else if (!beforeItemBeforeItemLength && afterItemBeforeItemLength) {
                                        newWordList.splice(partitionIndexForWordListCopy, 1, afterItem)
                                    }
                                    _that.wordList = JSON.parse(JSON.stringify(newWordList))
                                    _that.wordListCopyForEdit = JSON.parse(JSON.stringify(newWordList))
                                    _that.whoHaveToEditForWord = ''
                                    _that.$forceUpdate()
                                }
                            }
                            break
                        // 合并成一段：
                        case 'Backspace':
                            console.log('Backspace_Type')
                            if (!partitionIndexForContentList && (_that.lastStrForDelete === str) && partitionIndexForWordListCopy) {
                                const theBackspaceBefore = _that.wordListCopyForEdit[partitionIndexForWordListCopy - 1]
                                const theBackspaceCurrent = _that.wordListCopyForEdit[partitionIndexForWordListCopy]
                                const { beginTime, contentList: contentListForTheBackspaceBefore, endTime, username } = theBackspaceBefore
                                const { contentList: contentListForTheBackspaceCurrent } = theBackspaceCurrent
                                const newItem = {
                                    beginTime,
                                    contentList: [...contentListForTheBackspaceBefore, ...contentListForTheBackspaceCurrent],
                                    endTime,
                                    username
                                }
                                // 更新列表：
                                const newWordList = JSON.parse(JSON.stringify(_that.wordListCopyForEdit))
                                newWordList.splice(partitionIndexForWordListCopy - 1, 2, newItem)
                                _that.wordList = JSON.parse(JSON.stringify(newWordList))
                                _that.wordListCopyForEdit = JSON.parse(JSON.stringify(newWordList))
                                _that.whoHaveToEditForWord = ''
                                _that.$forceUpdate()
                            } else {
                                _that.lastStrForDelete = str
                                // 新添加逻辑，和新增文字走一个逻辑，如下，待提取成公共部分：
                                _that.wordListCopyForEdit = _that.wordListCopyForEdit.map(current => {
                                    if (current.beginTime === fatherItem.beginTime) {
                                        current.contentList = current.contentList.map(item => {
                                            if (item.wb === selfItem.wb) {
                                                item.w = e.target.innerText.trim()
                                                let wordInfoListForOldList = JSON.parse(JSON.stringify(item.wordInfoList))
                                                let wordInfoListForNewList = []
                                                for (let i = 0, len = e.target.children.length; i < len; i++) {
                                                    if (wordInfoListForOldList[i]) {
                                                        wordInfoListForNewList.push({
                                                            w: e.target.children[i].innerText,
                                                            wb: wordInfoListForOldList[i].wb,
                                                            we: wordInfoListForOldList[i].we
                                                        })
                                                    }
                                                }
                                                const wordInfoListForNewListReal = []
                                                wordInfoListForNewList.forEach(v => {
                                                    const wordOneList = (v.w || '').split('')
                                                    wordOneList.forEach(vv => {
                                                        wordInfoListForNewListReal.push({
                                                            w: vv,
                                                            wb: v.wb,
                                                            we: v.we
                                                        })
                                                    })
                                                })
                                                item.wordInfoList = JSON.parse(JSON.stringify(wordInfoListForNewListReal))          
                                            }
                                            return item
                                        })
                                    }
                                    return current
                                })
                                const newWordList = _that.wordListCopyForEdit.filter(item => (item.contentList || []).map(v => v.w).join(''))
                                // _that.wordList = JSON.parse(JSON.stringify(newWordList))
                                _that.wordListCopyForEdit = JSON.parse(JSON.stringify(newWordList))
                                _that.$forceUpdate()
                            }
                            break
                        // 普通编辑：
                        default:
                            console.log('default_Type')
                            _that.wordListCopyForEdit = _that.wordListCopyForEdit.map(current => {
                                if (current.beginTime === fatherItem.beginTime) {
                                    current.contentList = current.contentList.map(item => {
                                        if (item.wb === selfItem.wb) {
                                            item.w = e.target.innerText.trim()
                                            let wordInfoListForOldList = JSON.parse(JSON.stringify(item.wordInfoList))
                                            let wordInfoListForNewList = []
                                            for (let i = 0, len = e.target.children.length; i < len; i++) {
                                                if (wordInfoListForOldList[i]) {
                                                    wordInfoListForNewList.push({
                                                        w: e.target.children[i].innerText,
                                                        wb: wordInfoListForOldList[i].wb,
                                                        we: wordInfoListForOldList[i].we
                                                    })
                                                }
                                            }
                                            const wordInfoListForNewListReal = []
                                            wordInfoListForNewList.forEach(v => {
                                                const wordOneList = (v.w || '').split('')
                                                wordOneList.forEach(vv => {
                                                    wordInfoListForNewListReal.push({
                                                        w: vv,
                                                        wb: v.wb,
                                                        we: v.we
                                                    })
                                                })
                                            })
                                            item.wordInfoList = JSON.parse(JSON.stringify(wordInfoListForNewListReal))          
                                        }
                                        return item
                                    })
                                }
                                return current
                            })
                            const newWordList = _that.wordListCopyForEdit.filter(item => (item.contentList || []).map(v => v.w).join(''))
                            // _that.wordList = JSON.parse(JSON.stringify(newWordList))
                            _that.wordListCopyForEdit = JSON.parse(JSON.stringify(newWordList))
                            _that.$forceUpdate()
                            break

                    }
                }
            })
        },
        async saveForTheBackWordEdit (cb) {
            try {
                if (!(this.videoList || []).length) return
                const { id: liveId } = this.$route.query
                if (!liveId) return
                const params = {
                    liveId,
                    // videoId: this.videoId,
                    videoId: this.videoList[0].videoId,
                    paragraphList: JSON.stringify(this.wordListCopyForEdit)
                }
                this.$loading.show()
                const { data: result } = await this.$http.editWordContent(params)
                this.$loading.hide()
                if (result.code !== 200) return this.$message.error(result.msg)
                let { rt: resultReally } = result
                if (resultReally.resultStatus !== 1) return this.$message.error(resultReally.resultMessage? resultReally.resultMessage: '编辑失败')
                this.$message.success('编辑成功')
                this.mutationForSetEditBtnListShow(false)
                await this.getVideoCaptionsFile()
                this.setTextTracksForAliplayer()
                setTimeout(() => {
                    this.getText()
                    this.whoHaveToEditForWord = ''
                    if (cb && this.haveTheOnInputForWord) {
                        this.haveTheOnInputForWord = false
                        cb()
                    }
                }, 300)
            } catch (err) {
                console.log(err)
            }
        },
        //课程选集 逻辑
        selectLi(arr=[]){
          let li = []
          if(this.selectNum == 0 || arr.length < 2){
            this.$store.commit("select/edit",1)
          }
          if(arr.length>3 && this.selectNum == arr.length-1){
            this.$store.commit("select/edit",this.seriesLiveList.length-2)
          }
          li.push(arr[this.selectNum -1])
          li.push(arr[this.selectNum])
          li.push(arr[this.selectNum +1])
          return li.filter(item=>item)
        },
    },
    filters:{
      convertToChinese(num) {
        let N = [
          "零", "一", "二", "三", "四", "五", "六", "七", "八", "九"
        ];
        let str = num.toString();
        let len = num.toString().length;
        let C_Num = [];
        for (let i = 0; i < len; i++) {
          C_Num.push(N[str.charAt(i)]);
        }
        return C_Num.join('');
      }
    },
    computed: {
        ...mapGetters('common', ['editBtnListShow']),
      //仓库 记录相关集数
      selectNum(){
        return this.$store.state.select.seriesLiveListNum
      },
      //获取初始化  数据
      mySeriesLiveList: {
        get() {
          let arr = this.seriesLiveList.map((item, index) => {
            return { ...item, num: index }
          })
          arr.forEach(item=>{
            if(item.liveId==this.liveId){
              this.$store.commit('select/edit',item.num)
            }
          })
          return this.selectLi(arr)
        }
      }
    }
}

</script>

<style lang="less" scoped>
.audioAI-container {
    display: flex;
    align-items: flex-start;
    margin: 0 auto;
    width: 100%;
    .left-area {
        flex-basis: 65%;
    }
    .right-area {
        flex-basis: 35%;
        overflow: hidden;
    }
    .video-box {
        padding: 16px;
        .video-wrapper {
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            position: relative;
        }
        .video-container {
            position: relative;
        }
        .video-mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            // height: 100%;
            height: 450px;
            background-color: rgba(0, 0, 0, 1);
            z-index: 99;

            &-bg {
                width: 100%;
                height: 100%;
                background-size: contain;//auto 100%;
                background-repeat: no-repeat;
                background-position: center;
            }
            &-mantle {
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.4);
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .play-icon {
                width: 80px;
                height: 80px;
                cursor: pointer;
                background-image: url('@~assets/audioAI/play-icon.png');
                background-size: cover;
                &:hover {
                    background-image: url('@~assets/audioAI/play-icon-hover.png');
                }
            }
        }
        .transcode-mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            // height: 100%;
            height: 450px;
            background-color: rgba(0, 0, 0, 1);
            z-index: 2000;
            &-bg {
                width: 100%;
                height: 100%;
                background-size: auto 100%;
                background-repeat: no-repeat;
                background-position: center;
            }
            // &-mantle {
            //     width: 100%;
            //     height: 100%;
            //     background-color: rgba(0, 0, 0, 0.4);
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            // }
            img {
                width: 60px;
                height: 60px;
            }
            .mask {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6);
                color: #fff;
                line-height: 24px;
                font-size: 16px;
            }
        }
        // 替换播放器改动大，暂时只隐藏
        #container {
            display: none;
        }
        .video_container_new {
            width: 100%;
            height: calc(450px + 60px);
            /deep/.prism-player .prism-controlbar .prism-controlbar-bg {
                background: transparent;
            }
            /deep/.prism-player .prism-controlbar {
                height: 60px;
                background: #22232D !important;
                padding-top: 10px;
                box-sizing: border-box;
                bottom: -60px !important;
                z-index: 999;
            }
            /deep/.prism-progress {
                bottom: 42px !important;
            }
            /deep/.prism-setting-item.prism-setting-cc {
                display: none;
            }
            /deep/.prism-setting-item.prism-setting-audio {
                display: none;
            }
            /deep/.prism-setting-item.prism-setting-quality {
                display: none;
            }
            /deep/.prism-player.prism-fullscreen .prism-controlbar {
                bottom: 0px !important;
            }
            // 字幕的样式：
            /deep/.prism-cue {
                // transform: translateY(-60px) !important;
                transform: translateY(-20px) !important;
                div {
                    background: rgba(0, 0, 0, 0.5) !important;
                }
            }
            /deep/.subtitle-fill{
                color: rgb(255, 255, 255);
                background-color: rgba(0, 0, 0, 0.4);
                text-align: center;
                font: 22.5px sans-serif;
                position: absolute;
                bottom: 20px;
                left: 50%;
                width:100%;
                transform: translateX(-50%);
                word-break: break-word;
                word-wrap: break-word;
            }
            /deep/.prism-player.prism-fullscreen .prism-cue {
                transform: translateY(-60px) !important;
            }
            /deep/.prism-player .prism-info-left-bottom {
                left: 50%!important;
                background: rgba(0, 0, 0, 0.5) ;
                border-radius: 4px;
                padding: 0 16px;
                height: 28px;
                bottom: 100px;
            }
            /deep/.prism-player .prism-cover{
                background-size: contain;
            }
            /deep/.prism-player .prism-tooltip {
                z-index: 999;
            }
            /deep/.prism-player.prism-fullscreen .prism-tooltip {
                bottom: 80px !important;
            }
        }
        .video-tag {
            position: absolute;
            left: 0;
            top: 0;
            padding: 12px 16px;
            font-size: 16px;
            color: #fff;
            border-bottom-right-radius: 4px;
            background-color: #1672ff;
            z-index: 100;
        }
        #video {
            width: 100%;
            max-height: 470px;
            background-color: #000c1d;
        }
        .video-toos-bar {
            font-size: 18px;
            display: flex;
            justify-content: space-between;
            margin: 25px 0;
        }
        .video-title {
            font-weight: 500;
            color: var(--color-white);
            margin-right: 18px;
            max-width: 500px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .other-class {
            color: #1672ff;
            cursor: pointer;
            &-icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
                margin-right: 16px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .text-box {
        padding: 52px 32px 16px 16px;
        height: 730px;
        overflow: hidden;
        position: relative;
        &-header {
            position: absolute;
            top: 0;
            transform: translateY(50%);
            z-index: 999;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 36px;
            margin-top: -3px;
            // margin-bottom: 10px;
            background-color: var(--color-black);
            width: calc(100% - 48px);
            padding-bottom: 10px;
            .text-box-title {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: var(--color-white);
                font-weight: 500;
                img {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }
            }
            .saerch-input-box {
                display: flex;
                border: 1px solid #d0d3d6;
                border-radius: 6px;
                height: 32px;
                margin-left: 12px;
                width: 220px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                overflow: hidden;
                &.focus {
                    border-color: var(--color-active);
                }
            }
            .search-icon {
                margin: 0 8px 0 12px;
                color: #646a73;
            }
            .saerch-input {
                border: none;
                cursor: text;
                flex: 1 1;
                font-size: 14px;
                font-weight: 400;
                height: 22px;
                line-height: 22px;
                min-width: 0;
                outline: none;
                padding: 0;
                background-color: var(--color-black);
                color: var(--color-white);
            }
            .search-result {
                font-size: 14px;
                color: var(--color-white);
                line-height: 22px;
                padding-left: 2px;
                font-weight: 400;
                &-diveder {
                    margin: 0 2px;
                }
            }
            .diveder-line {
                color: #dee0e3;
                font-size: 14px;
                margin: 0 8px;
            }
            .tools-icon {
                cursor: pointer;
                color: var(--color-white);
            }
            .el-icon-arrow-down {
                margin: 0 8px;
            }
            .el-icon-error {
                flex-shrink: 0;
                margin-right: 8px;
                color: #8f959e;
            }
        }
        .subtitle-comp {
            display: flex;
            flex-direction: column;
            height: calc(100%);
            overflow: hidden;
            position: relative;
            width: 100%;
        }
        .subtitle-scroll-container {
            flex: 1 1;
            overflow-anchor: none;
            overflow-x: hidden;
            overflow-y: overlay;
            padding: 10px 0 0 0px;
            position: relative;
        }
        .subtitle-list-inner {
            border: 1px solid transparent;
            padding-bottom: 520px;
            padding-right: 10px;
            // position: relative;
        }
        // .text-box-content {
        //     // height: calc(100% - 40px);
        //     // height: 100%;
        //     // max-height: 876px;
        //     overflow-y: overlay;
        //     overflow-x: hidden;
        //     overflow-anchor: none;
        // }
        .empty-text {
            margin-top: 30px;
            background-color: rgba(255, 131, 22, 0.2);
            border-radius: 2px;
            height: 48px;
            font-size: 14px;
            color: var(--color-white);
            display: flex;
            align-items: center;
            padding: 0 16px;
            img {
                width: 14px;
                height: 14px;
                margin-right: 8px;
            }
        }
    }
}

.text-box /deep/ .paragraph-item {
    margin-top: 20px;
    display: flex;
    &:first-child {
        margin-top: 0;
    }
    .avatar {
        height: 32px;
        margin-right: 8px;
        margin-top: 2px;
        width: 32px;
        background-color: #3b74fc;
        border-radius: 50%;
        color: #fff;
        line-height: 32px;
        text-align: center;
        font-size: 12px;
        // img {
        //     width: 100%;
        //     height: 100%;
        // }
    }
    .paragraph-wrapper {
        flex: 1 1;
        font-size: 14px;
        color: var(--color-white);
        word-break: break-word;
        white-space: pre-wrap;
        line-height: 22px;
        text-align: justify;
    }
    .user-name {
        font-size: 14px;
        color: #8f959e;
        line-height: 16px;
        margin-bottom: 10px;
        span {
            color: #1672ff;
        }
    }
}
.text-box /deep/ .word {
    cursor: pointer;
}
.text-box /deep/ .content-highlight {
    background-color: #1672ff;
    color: #fff;
    padding: 3px 0;
}

.text-box /deep/ .search-select-back-color {
    background: #f8e6ab;
    color: #1f2329;
}
.text-box /deep/ .el-loading-mask {
    background-color: var(--color-black);
}
.text-box /deep/ .is-horizontal {
    display: none;
}
.text-box /deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
}
.container_can_edit_for_inner_word {
    border-bottom: 1px solid #95F204;
}
//课程选集
.video-select {
  margin-top: 20px;
  display: flex;
  align-items: center;

  .video-seTitle {
    font-size: 20px;
    color: #FFFFFF;
  }

  .video-con {
    color: #FFFFFF;
    font-size: 12px;
    text-align: center;
    line-height: 28px;
    width: 104px;
    height: 28px;
    border-radius: 2px;
    background-color: #20212c;
    cursor: pointer;
    margin: 0 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    padding: 0 8px;

    &:hover{
      background-color: #43444d;
      color: #dddddd;
    }
  }

  .video-cons {
    color: #dddddd;
    font-size: 12px;
    text-align: center;
    line-height: 28px;
    width: 104px;
    height: 28px;
    border-radius: 2px;
    background-color: #43444d;
    cursor: pointer;
    margin: 0 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    padding: 0 8px;
  }

  .video-many {
    width: 40px;
    color: #FFFFFF;
    font-size: 12px;
    text-align: center;
    line-height: 28px;
    height: 28px;
    border-radius: 2px;
    background-color: #20212c;
    cursor: pointer;
    margin: 0 4px;

    &:hover {
      background-color: #43444d;
      color: #dddddd;
    }
  }
}
</style>
