import { render, staticRenderFns } from "./VideoList.vue?vue&type=template&id=880cd980&scoped=true&"
import script from "./VideoList.vue?vue&type=script&lang=js&"
export * from "./VideoList.vue?vue&type=script&lang=js&"
import style0 from "./VideoList.vue?vue&type=style&index=0&id=880cd980&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "880cd980",
  null
  
)

export default component.exports