<template>
    <div class="container">
        <commonHeader leftNavAndBlueBtn ref="header" :comment='{statusShow:false}' :left-show='true' :rightHeader='true' />
        <div class="banner">
            <div class="banner-left">
                <div class="main-title">Ai剪辑、文字提取</div>
                <div class="des">基于智慧树自研的行业先进的音视频Ai识别技术，将课堂录像自动按主题进行切片；将语音直接转换为可编辑的文本；实现高精准、低延迟的识别体验。</div>
            </div>
            <div class="banner-right">
                <div class="title">体验产品</div>
                <el-button class="btn" disabled @click="handleUpload">
                    <div class="btn-slot">
                        <img class="icon" src="@/assets/audioAI/upload-icon.png" />
                        <span>上传本地视频</span>
                    </div>
                </el-button>
                <div class="des">
                    <div>*视频文件仅支持.MP4格式，视频大小不超过100M</div>
                    <div>*请提供发音清晰的课堂录制视频，在教室中拍摄的全程看清老师动作的视频识别效果最好</div>
                </div>
            </div>
        </div>
        <div class="main-section">
            <AudioAI ref="AudioAI" :isSample="isSample" :isAutoCutting="isAutoCutting" :isTranscode="isTranscode" :videoList="videoList"
                @handleUpload="handleUpload" @cutByMyself="cutByMyself" />
        </div>
        <common-footer></common-footer>
        <Upload ref="upload" @handleUploadSuccess="handleUploadSuccess" />
        <CutDialog v-if="showCutDialog" :show.sync="showCutDialog" :source="videoId" :uuid="uuid" :userId="userId" :special="special" :countToCut="countToCut" @handleUpdate="getAIVideoResultByUserId" />
    </div>
</template>
<script>
import commonHeader from '@/components/common/commonHeader.vue'
import commonFooter from '@/components/common/commonFooter.vue'
import AudioAI from '@/components/AudioAI'
import Upload from '@/components/Upload'
import CutDialog from './components/CutDialog'
import { mapGetters } from 'vuex'
export default {
    components: {
        commonHeader,
        commonFooter,
        AudioAI,
        Upload,
        CutDialog
    },
    computed: {
        ...mapGetters(['isLogin', 'userInfo'])
    },
    data() {
        return {
            videoList: [],
            isSample: false, //是否是示例视频
            timer: null,
            showCutDialog: false, //手动剪辑弹窗
            isAutoCutting: false, //是否开始自动切片
            isTranscode: false, //是否转码完成
            videoId: '',
            uuid: '',
            special: '',
            isPollingGet: false,
            lastModifiedTime: '', //上次更新时间
            countToCut: 0,
            userId: 0
        }
    },
    async mounted() {
        await this.$store.dispatch('userInfo/getInfo')
        this.getVideoList()
    },
    beforeDestroy() {
        this.timer && clearInterval(this.timer)
    },
    methods: {
        // 获取视频列表
        async getVideoList() {
            if (this.isLogin) {
                // 登陆状态，获取用户上传的视频
                this.getAIVideoResultByUserId()
            } else {
                // 未登陆状态，获取示例视频
                this.getSampleAIVideoResult()
            }
        },
        // 轮询获取切片结果
        pollingGet() {
            this.isPollingGet = true
            this.timer = setInterval(() => {
                this.getAIVideoResultByUserId()
            }, 10000)
        },
        // 登陆状态通过用户id获取切片结果
        async getAIVideoResultByUserId(cb) {
            const { data } = await this.$http.getAIVideoResultByUserId()
            if (data.code == 200 && data.rt) {
                // 如果视频未更新，则不替换
                if (this.lastModifiedTime != data.rt.lastModifiedTime) {
                    this.videoList = data.rt.videoList
                    this.lastModifiedTime = data.rt.lastModifiedTime
                }
                this.isSample = data.rt.isSample
                this.isAutoCutting = data.rt.isAutoCutting
                data.rt.videoList.length && (this.isTranscode = data.rt.videoList[0].isTranscode)
                // 是否需要轮询查询
                if (!this.isPollingGet && data.rt.isPolling) {
                    this.pollingGet()
                }
                // 结束轮询
                if (!data.rt.isPolling && this.timer) {
                    this.isPollingGet = false
                    clearInterval(this.timer)
                    this.timer = null
                }
            }
            cb && cb()
        },
        // 未登陆状态，获取示例视频
        async getSampleAIVideoResult() {
            const { data } = await this.$http.getSampleAIVideoResult()
            if (data.code == 200 && data.rt) {
                // 如果示例视频未更新，则不替换
                if (this.lastModifiedTime != data.rt.lastModifiedTime) {
                    this.videoList = data.rt.videoList
                    this.lastModifiedTime = data.rt.lastModifiedTime
                }
                this.isSample = data.rt.isSample
                this.isAutoCutting = data.rt.isAutoCutting
                data.rt.videoList.length && (this.isTranscode = data.rt.videoList[0].isTranscode)
            }
        },
        // 登陆点击
        async handleUpload() {
            // 需登录
            if (this.isLogin) {
                this.$refs['upload'].handleUpload();
            } else {
                this.login()
            }
        },// 登录
      login() {
        this.$refs['header'].loginBtn()
      },

        // 上传成功
        async handleUploadSuccess(params) {
            const { data } = await this.$http.uploadVideo(params)
            if (data.code == 200 && data.rt.resultStatus == 1) {
                this.$message.success('上传成功')
                // 暂停之前视频
                this.$refs['AudioAI'] && this.$refs['AudioAI'].handlePause()
                // 轮询获取切片结果
                this.getAIVideoResultByUserId()
            }
        },
        // 手动剪辑按钮点击事件
        async cutByMyself(videoId, isSample) {
            // 需登录
            if (this.isLogin) {
                // 如果是示例视频，调用上传接口进行关联
                if (isSample) {
                    const params = {
                        videoId,
                        videoName: '示例视频',
                        videoDuration: 0
                    }
                    await this.$http.uploadVideo(params)
                }
                this.videoId = videoId
                // const userInfo = JSON.parse(localStorage.getItem('User_userInfo') || '')
                const userInfo = JSON.parse(this.userInfo || '')
                this.uuid = userInfo.uuid
                this.userId = userInfo.userId
                this.special = isSample ? 1 : 0
                this.countToCut ++
                this.showCutDialog = true
            } else {
                this.login()
            }
        }
    }
}
</script>
<style lang="less" scoped>
.banner {
    background-image: url('~assets/audioAI/banner-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 310px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-left {
        width: 740px;
        margin-right: 90px;
        color: #fff;
        .main-title {
            font-size: 40px;
            font-weight: 500;
            line-height: 60px;
        }
        .des {
            margin-top: 16px;
            width: 100%;
            font-size: 20px;
            line-height: 28px;
        }
    }
    &-right {
        width: 539px;
        height: 222px;
        background: rgba(255, 255, 255, 0.1);
        text-align: center;
        padding: 32px;
        .title {
            font-size: 24px;
            color: #fff;
            line-height: 36px;
            font-weight: 500;
        }
        .btn {
            margin-top: 20px;
            border: none;
            border-radius: 4px;
            width: 270px;
            height: 52px;
        }
        .btn-slot {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #1672ff;
            font-size: 20px;
            .icon {
                width: 20px;
                height: 20px;
                margin-right: 12px;
            }
        }
        .des {
            margin-top: 16px;
            font-size: 12px;
            color: #fff;
            line-height: 20px;
        }
    }
}
.main-section {
    padding: 40px 0;
}
.footer {
    height: 330px;
    background-image: url('~assets/audioAI/footer-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #1f2329;
    &-title {
        font-size: 40px;
        font-weight: 500;
        line-height: 48px;
        margin-bottom: 16px;
    }
    &-des {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        margin-bottom: 16px;
    }
    .btn {
        width: 154px;
        height: 54px;
        font-size: 18px;
        &.contact {
            border-color: #1672ff;
            color: #1672ff;
        }
    }
}
.container /deep/ .el-button {
    &:focus,
    &:hover {
        // background-color: #f1f7ff;
        background-color: rgba(0, 0, 0, 0.1);
    }
}
</style>
