<template>
    <div class="dialog-container">
        <el-dialog class="cut-dialog" :modal-append-to-body="false" :close-on-click-modal="false" :show-close="false" :withHeader="false"
            :visible.sync="show" width="80%" :before-close="handleClose">
            <div class="cut-dialog-body">
                <iframe class="iframe" :src="src" />
                <div class="close-btn" @click="handleClose" role="button"></div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        source: {
            type: String | Number,
            default: ''
        },
        uuid: {
            type: String,
            default: ''
        },
        special: {
            type: String | Number,
            default: 0
        },
        countToCut: {
            type: Number,
            default: 0
        },
        userId: {
            type: Number | String,
            default: 0
        }
    },
    computed: {
        src() {
            /**
             * source: videoId
             * uuid: 用户uuid
             * special: 是否是示例视频
             * countToCut: 打开的次数，只有第一次展示引导
             */
            // let src = `https://ai-video-h5.zhihuishu.com/videoSection/index.html?source=${this.source}&uuid=${this.uuid}&special=${this.special}&countToCut=${this.countToCut}`
            // 预发/线上地址
            let src = `https://www.livecourse.com/aiVideo?source=${this.source}&userId=${this.userId}&special=${this.special}&countToCut=${this.countToCut}`
            // 本地开发测试地址
            // let src = `http://localhost:8090/aiVideo?source=${this.source}&userId=${this.userId}&special=${this.special}&countToCut=${this.countToCut}`
            console.log('src🐶:>>>>>>>', src, 'src/views/AIExperience/components/CutDialog.vue')
            return src
        }
    },
    methods: {
        handleClose() {
            // 立即请求一次视频列表
            // this.$emit('handleUpdate')
            // this.$emit('update:show', false)
            this.$emit('handleUpdate', () => {
                this.$emit('update:show', false)
            })
        }
    }
}
</script>
<style lang="less" scoped>
.cut-dialog /deep/ .el-dialog__header {
    display: none;
}
.cut-dialog /deep/ .el-dialog__body {
    padding: 0;
}
.cut-dialog /deep/ .el-dialog {
    margin-top: 10vh !important;
}
.cut-dialog-body {
    height: 655px;
    position: relative;
    .iframe {
        width: 100%;
        height: 100%;
        border: none;
        ::v-deep img {
            width: 100%;
            object-fit: cover;
        }
    }
    .close-btn {
        position: absolute;
        top: -52px;
        right: -52px;
        width: 36px;
        height: 36px;
        background-image: url('@~assets/audioAI/close-icon.png');
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}
.dialog-container /deep/ .v-modal {
    opacity: 1;
    backdrop-filter: blur(10px);
    background: rgba(38, 47, 62, 0.2);
}
</style>
