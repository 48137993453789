<template>
  <div class='applyOpen-wrap'>
    <!-- 头部 -->
    <common-header :leftNavAndBlueBtn='true' :comment='{statusShow:false}' :left-show='true'
                   :rightHeader='true'></common-header>
    <!-- 宣传栏 -->
    <div class='publicity-outer'>
      <div class='publicity-inner'>
        <div class='title1 PFMC'>同步课堂试用计划</div>
        <div class='title2 PFMC'>助力高校课堂全面数字升级、降本增效</div>
        <div class='describe PFRC'>智慧树「试用计划顾问」为你提供专属服务 帮助高校更快更好的使用</div>
      </div>
    </div>
    <!-- 一键预约 -->
    <div class='order-wrap flex'>
      <!-- 左边 -->
      <div v-if='isShowInfo' class='left-wrap'>
        <div class='introduce PFRC'>为了帮助高校课堂更好的进行数字化升级，应对在线教学挑战，2022年7月1日，智慧树正式推出「同步课堂试用计划」，从产品和服务两个维度助力广大院校</div>
        <div class='serve-wrap flex'>
          <div class='label PFMC'>服务</div>
          <div class='txt PFRC'>免费开放500个名额</div>
        </div>
        <div class='nowGet PFMC'>把教室接入同步课堂网，立刻获得：</div>
        <div class='content1'>
          <div class='dot'></div>
          <div class='txt PFRC'>随时在教室开启一场直播教学，线上线下共上一堂课</div>
        </div>
        <div class='content2'>
          <div class='dot'></div>
          <div class='txt PFRC'>随时记录教学过程，课后即可获得AI自动剪辑过的课堂录像</div>
        </div>
        <div class='content3'>
          <div class='dot'></div>
          <div class='txt PFRC'>随时与异地教室开展同步课堂，多间教室同上一堂课</div>
        </div>
      </div>
      <!-- 右边 -->
      <div v-if='isShowInfo' class='right-wrap'>
        <div class='places PFMC'>免费开放500个名额</div>
        <div class='name PFMC'>姓名 <span>*</span></div>
        <el-input v-model='formData.nameVal' placeholder='请输入姓名' :maxlength='10' clearable></el-input>
        <div class='name PFMC'>手机号 <span>*</span></div>
        <el-input v-model='formData.telVal' placeholder='请输入手机号' oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  :maxlength='11' clearable></el-input>
        <div class='name PFMC'>学校名称 <span>*</span></div>
        <el-input v-model='formData.schoolVal' placeholder='请输入学校名称' :maxlength='30' clearable></el-input>
        <el-button class='nowOrder' @click='submitBtn' type='info'
                   :style="{ background: changeBtnColor ? '#1672FF' : '#BBC0C3' }">一键预约
        </el-button>
        <div class='prompt PFRC'>1、预约后，我们将审核您的资料，审核通过后顾问将通过电话与您联系（不会暴露您的具体手机号码）</div>
        <div class='prompt PFRC'>2、若您希望删除已提交的信息，可通过联系客服处理</div>
      </div>
      <!-- 提交成功 -->
      <div v-if='!isShowInfo' class='success-wrap flex'>
        <img src='@/assets/applyOpen/success.png' alt=''>
        <div class='info PFMC'>提交成功</div>
        <div class='info PFMC'>同步课堂网团队将尽快与您联系</div>
      </div>
    </div>
    <!-- 联系我们 -->
    <common-footer></common-footer>
  </div>
</template>

<script>
import commonHeader from 'components/common/commonHeader.vue'
import commonFooter from 'components/common/commonFooter.vue'

export default {
  name: 'applyOpen',
  components: {
    commonHeader,
    commonFooter
  },
  data() {
    return {
      isShowInfo: true,
      formData: {
        nameVal: '',
        telVal: '',
        schoolVal: ''
      },
      changeBtnColor: false,
      source: 1
    }
  },
  watch: {
    formData: {
      handler(newVal) {
        if (newVal.nameVal && newVal.telVal && newVal.schoolVal) {
          this.changeBtnColor = true
        } else {
          this.changeBtnColor = false
        }
      },
      deep: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    //判断是否翻转课跳过来的页面
    init() {
      const source = this.getParams(window.location.search)
      if (source == 1) {
        this.source = 2
        if (localStorage.getItem('User_userInfo')) {
          const users = JSON.parse(localStorage.getItem('User_userInfo'))
          this.formData.nameVal = users.realname
          this.formData.telVal = users.mobile
          this.formData.schoolVal = users.schoolName + '\t- 来自：翻转课'
        } else {
          let resultUrl = location.href
          location.href = `https://www.livecourse.com/api/simple-class/zhs/redirectUrlAndGetUserInfo?redirectUrl=${resultUrl}`
        }
      }

    },

    //获取翻转课传递过来的参数
    getParams(res) {
      let query = res.substring(1)
      let values = query.split('&')
      for (let i = 0; i < values.length; i++) {
        let pos = values[i].indexOf('=')
        if (pos === -1) continue
        // let paramName = values[i].substring(0,pos);
        return values[i].substring(pos + 1)
      }
    },

    //一键预约
    async submitBtn() {
      if (!this.formData.nameVal) return this.$message.error('姓名不为空')
      if (!this.formData.telVal) return this.$message.error('手机号不为空')
      const reg = /^1((3[\d])|(4[5,6,7,9])|(5[0-3,5-9])|(6[5-7])|(7[0-8])|(8[\d])|(9[1,8,9]))\d{8}$/
      if (!reg.test(this.formData.telVal)) return this.$message.error('请输入正确的手机号')
      if (!this.formData.schoolVal) return this.$message.error('学校名称不为空')
      // 发邮件
      const params = {
        realName: this.formData.nameVal,
        phone: this.formData.telVal,
        schoolName: this.formData.schoolVal,
        source: this.source
      }
      const { data } = await this.$http.applyForOpening(params)
      if (data.code === 200) {
        // return this.$message.success('预约成功')
        this.isShowInfo = false
        this.formData = {}
      } else {
        return this.$message.error('预约失败')
      }
    }
  }
}
</script>

<style lang='less' scoped>
// 宣传栏
.publicity-outer {
  height: 310px;
  background: url('https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/2319282dd64f4804b7f85fca9868fac4.png') no-repeat;
  background-size: 100% 100%;

  .publicity-inner {
    width: 902px;
    min-width: 902px;
    height: 310px;
    margin: 0 auto;
    overflow: hidden;

    .title1, .title2 {
      font-size: 40px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 60px;
    }

    .title1 {
      margin-top: 73px;
    }

    .describe {
      font-size: 20px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28px;
      margin-top: 16px;
    }
  }
}

// 一键预约
.order-wrap {
  width: 902px;
  min-width: 902px;
  height: 621px;
  margin: 0 auto;
  justify-content: space-between;
  // 左边
  .left-wrap {
    width: 386px;
    margin-top: 60px;

    .introduce {
      font-size: 14px;
      font-weight: 400;
      color: #1F2329;
      line-height: 20px;
      margin-bottom: 38px;
    }

    .serve-wrap {
      align-items: center;
      margin-bottom: 22px;

      .label {
        font-size: 20px;
        font-weight: 500;
        color: #1F2329;
        line-height: 20px;
        margin-right: 8px;
      }

      .txt {
        font-size: 14px;
        font-weight: 400;
        color: #1672FF;
        line-height: 18px;
      }
    }

    .nowGet {
      font-size: 18px;
      font-weight: 500;
      color: #1F2329;
      line-height: 18px;
      margin-bottom: 32px;
    }

    .content1, .content2, .content3 {
      width: 385px;
      background: #F7F9FF;
      border-radius: 4px;
      padding: 15px 16px 15px 32px;
      box-sizing: border-box;
      margin-bottom: 20px;
      position: relative;

      .dot {
        width: 8px;
        height: 8px;
        background: #1672FF;
        border-radius: 50%;
        position: absolute;
        left: 16px;
        top: 21px;
      }

      .txt {
        font-size: 14px;
        font-weight: 400;
        color: #51565D;
        line-height: 20px;
      }
    }

    .content2 {
      background: #ECF9FE;
    }

    .content3 {
      background: #EEF6ED;
    }

  }

  // 右边
  .right-wrap {
    width: 391px;
    height: 481px;
    box-shadow: 0px 2px 15px 0px rgba(5, 20, 42, 0.1000);
    border-radius: 8px;
    margin-top: 60px;
    padding: 32px 32px 0 32px;
    box-sizing: border-box;

    .places {
      font-size: 21px;
      font-weight: 500;
      color: #1F2329;
      line-height: 20px;
    }

    .name {
      font-size: 14px;
      font-weight: 500;
      color: #1F2329;
      line-height: 24px;
      margin-top: 18px;
      margin-bottom: 6px;

      span {
        color: red;
      }
    }

    ::v-deep .nowOrder {
      width: 100%;
      height: 40px;
      border: 0;
      margin: 18px 0;

      span {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 16px;
      }
    }

    .prompt {
      font-size: 12px;
      font-weight: 400;
      color: #A6A6A6;
      line-height: 18px;
    }
  }

  // 提交成功
  .success-wrap {
    width: 100%;
    flex-direction: column;
    align-items: center;

    img {
      width: 100px;
      height: 100px;
      margin: 160px 0 28px 0;
    }

    .info {
      font-size: 20px;
      font-weight: 500;
      color: #1F2329;
      line-height: 30px;
    }
  }
}
</style>
