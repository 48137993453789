<template>
  <!--  历史直播-->
  <div class='box'>

    <!--header-->
    <div class='header'>
      <!--left-->
      <div class='left' @click='pushHome'>
        <img class='logo-icon' src='@/assets/homePage/logo.png' alt='同步课堂'>
        <div class='logo-font'>同步课堂</div>
      </div>
      <!--right-->
      <div class='right'>
        <el-popover
          placement='top-end'
          width='200'
          trigger='hover'
          :visible-arrow='false'>

          <div class='loginInfo'>
            <div class='top flex'>
              <img class='top-img' :src='userInfo.avatar' alt=''>
              <div class='user-name PFRC'>{{ userInfo.realname }}</div>
              <div class='school PFRC'>{{ userInfo.schoolName }}</div>
            </div>
            <div class='line-gap'></div>
            <div class='exit-wrap flex'>
              <img src='@/assets/homePage/tuichu.png' alt=''>
              <div class='label PFRC' @click='logoutBtn'>退出登录</div>
            </div>
          </div>


          <div slot='reference'>
            <img class='picture' :src='userInfo.avatar' alt=''>
            <img class='downArrow' src='@/assets/homePage/downArrow.png' alt=''>
          </div>
        </el-popover>
      </div>
    </div>

    <!--main-->
    <div class='main'>
      <div class='mainL'>
        <div class='first-box'>
          <div class='box-css' @click='funcLeft("launchLive")'>
            <img
              src='https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202209/c6d612a1662c4b959c7b99d23feb3a51.png'
              alt=''>
            <p>发起新直播</p>
          </div>
          <div class='box-css' @click='funcLeft("addLive")'>
            <img
              src='https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202209/4213cb178afb4ac4af301cc0f985d279.png'
              alt=''>
            <p>加入直播</p>
          </div>
        </div>
        <div class='two-box'>
          <div class='box-css' @click='funcLeft("upDown")'>
            <img
              src='https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202209/5f6d0bf7dee947488cd25cb9875e1dc2.png'
              alt=''>
            <p>上传课堂录像</p>
          </div>
          <div class='box-css' @click='funcLeft("applyOpen")'>
            <img
              src='https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202209/f04b1549f5da4345aabee39e00c0a26c.png'
              alt=''>
            <p>申请开通</p>
          </div>
        </div>
      </div>

      <div class='mainR'>
        <div class='maiNever' v-show='hisList.length===0 && startList.length===0'>暂无直播记录！！</div>
        <div v-show='hisList.length || startList.length'>
          <div v-show='startList.length' class='toStart'>
            <div class='title'>待开始</div>
            <div class='content'>

              <div class='content-ge' v-for='(item,index) in startList' :key='index' @click='startToGet(item)'>
                <div class='cssLeft'>
                  <img
                    style='width: 112px;height: 63px'
                    src='https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202209/0c28f599b9d54043882d17ab5a91847d.png'
                    alt=''>
                  <div class='ge-title'>
                    <div class='ge-one'>
                      <span :title='item.liveName.length>20?item.liveName:""'
                            :class='item.liveName.length>20?"titlesW":"titles"'> {{ item.liveName }} </span>
                      <span class='time'>
                                    <span
                                      v-if='new Date(item.startTime).toDateString()!==new Date().toDateString()'>{{ item.startTime | dateFormat('YYYY/MM/DD')
                                      }}</span>
                                    <span v-else>今天</span>
                                    {{ item.startTime | dateFormat('HH:mm') }} - {{ item.endTime | dateFormat('HH:mm')
                        }}
                          </span>
                    </div>
                    <div class='ge-two'>
                      <span class='spanBox'>
                          <span class='spanOneCode'>主讲参加码：<span class='code'>{{ item.teacherJoinCode }}</span></span>
                          <span class='spanCode'>听课教室参加码：<span class='code'>{{ item.assistantJoinCode }}</span></span>
                          <span class='spanCode'>学生参加码：<span class='code'>{{ item.studentJoinCode }}</span></span>
                      </span>
                      <span class='copy' @click.stop='copy(item)'>复制</span>
                    </div>
                  </div>
                </div>
                <div class='cssRight' v-if='item.seriesCourse'>
                  系列课程
                </div>
              </div>

            </div>
          </div>
          <div v-show='hisList.length' class='historyLive'>
            <div class='hisTitle'>历史记录</div>
            <div class='content' v-loading='loading' element-loading-text='数据加载中...'
                 element-loading-background='rgba(0, 0, 0, 0.5)' v-infinite-scroll='loadDates'>

              <div class='content-ge' @click='goToVideo(item)' v-for='(item,index) in hisList' :key='index'>

                <div class='cssLeft'>
                  <img v-if='!item.videoImageUrl' style='width: 112px;height: 63px'
                       src='https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202209/3be7356ce5ca4bec8f499664a1900b25.png'
                       alt=''>
                  <img v-else style='width: 112px;height: 63px'
                       :src='item.videoImageUrl'
                       alt=''>
                  <div class='ge-title'>
                    <div class='ge-one'>
                      <span :title='item.liveName && item.liveName.length>20?item.liveName:""'
                            :class='item.liveName && item.liveName.length>20?"titlesW":"titles"'> {{ item.liveName }} </span>
                      <span class='time'>
                            <span
                              v-if='new Date(item.startTime).toDateString()!==new Date().toDateString()'>{{ item.startTime | dateFormat('YYYY/MM/DD')
                              }}</span>
                            <span v-else>今天</span>
                            {{ item.startTime | dateFormat('HH:mm') }} - {{ item.endTime | dateFormat('HH:mm') }}
                          </span>
                    </div>
                    <div class='ge-two'>
                      <span class='spanOneCode'>回放参加码：<span class='code'>{{ item.studentJoinCode }}</span></span>
                      <span class='copy' @click.stop='copy(item)'>复制</span>
                    </div>
                  </div>
                </div>
                <div class='cssRight' v-if='item.seriesCourse'>系列课程</div>
              </div>
              <div v-if='andMore' class='andMore'>加载中...</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 提示有一堂课的弹框： -->
    <my-dialog class='dialog-wrap' width='382px' pLR='0' borderRadius='8px' :showMaskFlag='true'
               :dialogFlag='dialogForHaveCourse' @hideMask='dialogForHaveCourse = false'>
      <div class='dialog_inner_container_for_have_course'>
        <div class='dialog_inner_container_for_have_course_header'>系统提示</div>
        <div class='dialog_inner_container_for_have_course_body'>
          <img class='dialog_inner_container_for_have_course_body_img'
               src='https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/72bffef3700d4675b001d70a8cf39e7a.png'
               alt=''>
          <p class='dialog_inner_container_for_have_course_body_title'>
            您有一门课正在进行中，结束后才可开始新课
          </p>
          <p class='dialog_inner_container_for_have_course_body_btn' @click='goToOfHaveCourse'>
            进入课堂
          </p>
        </div>
      </div>
    </my-dialog>
    <!--判断是否大于2小时弹框倒计时-->
    <my-dialog :dialogFlag='dialogBeforeStartOrEnd' width='320px' pLR='0' :showMaskFlag='false'>
      <div class='dialog_inner_container'>
        <div class='dialog_inner_container_top'>
          <p :title='this.startData.liveName' class='dialog_inner_container_top_title'>
            {{ this.startData.liveName }}
          </p>
          <p class='dialog_inner_container_top_time'>
            {{ this.startData.startTime | dateFormat('YYYY-MM-DD') }}
            {{ this.startData.startTime | dateFormat('HH:mm') }}-{{ this.startData.endTime | dateFormat('HH:mm') }}
          </p>
        </div>
        <div class='dialog_inner_container_nav'>
          <img class='dialog_inner_container_nav_img'
               src='https://image.zhihuishu.com/zhs/ablecommons/zhangying/202112/607026ed7552455689afbd1dda655617.png' />
          <!-- content: -->
          <div class='dialog_inner_container_nav_content'>
            <div class='dialog_inner_container_nav_content_explain'>
              <!--              额...要等到直播开始才能进入直播教室哦！-->
              直播开始前2小时内才能进入课堂哦！
            </div>
            <div class='dialog_inner_container_nav_content_time'>
              <p class='title'>直播倒计时</p>
              <p class='time' v-if='day'>{{ day }}天</p>
              <p class='time' v-else>{{ hr }}:{{ min }}:{{ sec }}</p>
            </div>
          </div>
          <!-- footer: -->
          <div class='dialog_inner_container_nav_footer_btn' @click='mock'>
            关闭
          </div>
        </div>
      </div>
    </my-dialog>
    <Upload ref="uploadVideo" @handleUploadSuccess="handleUploadSuccess" />
  </div>
</template>

<script>
import moment from 'moment'
import dataUtils from '@/utils/toolsForDate'
import myDialog from '../../components/common/myDialog.vue'
import Upload from '@/components/Upload'

export default {
  name: 'index',
  components: {
    myDialog,
    Upload
  },
  data() {
    return {
      isShowMene: false,
      userInfo: {
        avatar: '',
        realname: '',
        schoolName: ''
      },

      hisList: [],
      startList: [],
      loading: false,
      andMore: false,
      dialogForHaveCourse: false,
      dialogBeforeStartOrEnd: false,
      pageInfo: {
        pageNum: 1,
        pageSize: 10
      },
      totalCount: 0,
      goToOfHaveCourseType: '',
      goToOfHaveCourseOfLiveId: '',
      rtData: {},

      startData: {},
      day: 0,//天
      hr: 0,//时
      min: 0,//分
      sec: 0,//秒
      inviteCodeTypeForFlowOfOverall: '',   //1：直播邀请码(学生邀请码/主讲邀请码) 2：课程邀请码 3：活动邀请码
      inviteCodeTypeForFlowOfLive: ''  //1：主讲邀请码 2：助教邀请码 3：学生邀请码

    }
  },
  mounted() {
    this.init()
  },
  methods: {
    //返回
    mock() {
      this.dialogBeforeStartOrEnd = false
      clearInterval(this._interval)
    },

    //获取当前时间并对比
    getCurentTime(symbolExpressYear = '-', symbolExpressMonth = '-', symbolExpress = ':') {
      let date = new Date()
      let yearTime = date.getFullYear() // 获取完整的年份(4位,1970)
      let MonthTime = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1) //
      // 获取月份(0-11,0代表1月,用的时候记得加上1)
      let dayTime = date.getDate() > 9 ? date.getDate() : '0' + date.getDate() // 获取日(01-31)

      let HoverTIme = date.getHours() > 9 ? (date.getHours()) : ('0' + date.getHours()) // 获取小时数(0-23)
      let MinyutesTime = date.getMinutes() > 9 ? (date.getMinutes()) : ('0' + date.getMinutes()) // 获取分钟数(0-59)
      let secondsTime = date.getSeconds() > 9 ? (date.getSeconds()) : ('0' + date.getSeconds()) // 获取秒(0-59)

      return (
        yearTime +
        symbolExpressYear +
        MonthTime +
        symbolExpressMonth +
        dayTime +
        ' ' +
        HoverTIme +
        symbolExpress +
        MinyutesTime +
        symbolExpress +
        secondsTime
      )
    },

    //  比较时间的大小
    tab(date1, date2) {
      // 因为苹果的时间格式是2020/10/19/ 00:00:00这样的格式；进行处理下
      let date_initial = date1.replace(/-/g, '/')
      let data_finsh = date2.replace(/-/g, '/')

      let oDate1 = new Date(date_initial)
      let oDate2 = new Date(data_finsh)
      if (oDate1.getTime() > oDate2.getTime()) {
        return 1
      } else if (oDate1.getTime() < oDate2.getTime()) {
        return 2
      } else if (oDate1.getTime() === oDate2.getTime()) {
        return 0
      }
    },

    //倒计时
    countdown(n) {
      const end = Date.parse(new Date(n))
      const now = Date.parse(new Date())
      const msec = end - now
      let day = parseInt(msec / 1000 / 60 / 60 / 24)
      let hr = parseInt(msec / 1000 / 60 / 60 % 24)
      let min = parseInt(msec / 1000 / 60 % 60)
      let sec = parseInt(msec / 1000 % 60)
      this.day = day
      this.hr = hr > 9 ? hr : '0' + hr
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      const that = this
      this._interval = setTimeout(function() {
        that.countdown(n)
      }, 1000)
    },

    //点击待开始直播，如果距离开始时间在两小时之内，点击条目条跳转到学生端观看广告流，大于两小时到直播倒计时页面
    startToGet(item) {
      let nowTime = this.getCurentTime()  //当前时间
      let end = new Date(item.startTime)
      let start = this.timeFormat(new Date(end.setHours(end.getHours() - 2)))//开始时间减去俩小时，在start 与end范围之内不弹框
      let timeA = this.tab(nowTime, start)
      let timeB = this.tab(nowTime, item.endTime)

      if (timeA === 1 && timeB === 2) {
        switch (item.liveType) {
          case 1:
            // window.open(`https://www.livecourse.com/teacher/StudentPicture?liveId=${item.liveId}&sourceofcome=sourcefromweb`, '_blank')
            this.goClassBtn(item)
            break
          case 2:
            // window.open('https://www.livecourse.com/teacher/Home?typeForCreate=join', '_self')
            this.goClassBtns(item)
            break
        }
      } else {
        this.startData = item
        this.dialogBeforeStartOrEnd = true
        this.countdown(item.startTime)
      }
    },

    // 点击待开始直播  在两小时内出发改方法 主讲身份参加
    async goClassBtn(res) {
      try {
        const { data: resultForGetInviteCodeInfo } = await this.$http.getInviteCodeInfo({ inviteCode: res.teacherJoinCode })
        console.log(resultForGetInviteCodeInfo)
        if (resultForGetInviteCodeInfo.code !== 200) return this.$message.error('当前网络异常，请检查网络！')
        if (resultForGetInviteCodeInfo.rt.resultStatus === -1) return this.$message.error('当前网络异常，请检查网络！')
        // codeSource 1：直播邀请码(学生邀请码/主讲邀请码) 2：课程邀请码 3：活动邀请码
        // type 1：主讲邀请码 2：助教邀请码 3：学生邀请码
        this.inviteCodeTypeForFlowOfOverall = resultForGetInviteCodeInfo.rt.codeSource
        this.inviteCodeTypeForFlowOfLive = resultForGetInviteCodeInfo.rt.type

        // 新增逻辑：
        // 需要判断是主讲收视，如果是，就走新的流程：
        if (this.inviteCodeTypeForFlowOfOverall === 1 && this.inviteCodeTypeForFlowOfLive === 1) {
          console.log('主讲码')

          const { data: result } = await this.$http.teacherJoinLive({
            inviteCode: res.teacherJoinCode,
            userName: this.userInfo.realname
          })
          console.log(result, 'teacherJoinLive')
          if (result.code !== 200) return this.$message.error('当前网络异常，请检查网络！')
          const { rt: resultReally } = result
          if (resultReally.resultStatus !== 1 && resultReally.resultStatus !== 2) return this.$message.error('当前网络异常，请检查网络！')
          // 如果发现当前用户在此直播当中是收视则不能进
          if (resultReally.role === 1) {
            if (resultReally.resultStatus === 1) {
              if (Object.prototype.hasOwnProperty.call(resultReally, 'liveCourseId')) {
                // this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLEONEFORLC'
                this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
              } else {
                // this.$router.push({
                //   name: 'StudentPicture',
                //   query: { liveId: resultReally.liveId, sourceofcome: 'sourcefromweb' }
                // })
                window.open(`https://www.livecourse.com/teacher/StudentPicture?liveId=${resultReally.liveId}&sourceofcome=sourcefromweb`, '_blank')
              }
            }
            if (resultReally.resultStatus === 2) {
              if (Object.prototype.hasOwnProperty.call(resultReally, 'liveCourseId')) {
                // this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLEONEFORLC'
                this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
              } else {
                this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLEONE'
                this.dialogForHaveCourse = true
                this.goToOfHaveCourseOfLiveId = resultReally.liveId
              }
            }
          } else if (resultReally.role === 2) {
            if (Object.prototype.hasOwnProperty.call(resultReally, 'liveCourseId')) {
              // this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLETWOFORLC'
              this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
            } else {
              this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLETWO'
              this.dialogForHaveCourse = true
              this.goToOfHaveCourseOfLiveId = resultReally.liveId
            }
          }
          return
        }

        if (this.inviteCodeTypeForFlowOfOverall === 1 && this.inviteCodeTypeForFlowOfLive === 2) {
          console.log('收视码')

          const { data: result } = await this.$http.teacherJoinLive({
            inviteCode: res.teacherJoinCode,
            userName: this.userInfo.realname
          })
          console.log(result, 'teacherJoinLive')
          if (result.code !== 200) return this.$message.error('当前网络异常，请检查网络！')
          const { rt: resultReally } = result
          if (resultReally.resultStatus !== 1 && resultReally.resultStatus !== 2) return this.$message.error('当前网络异常，请检查网络！')
          // 如果发现当前用户在此直播当中是主讲则不能进
          if (resultReally.role === 1) {
            if (Object.prototype.hasOwnProperty.call(resultReally, 'liveCourseId')) {
              // this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLEONEFORLC'
              this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
            } else {
              this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLEONE'
              this.dialogForHaveCourse = true
              this.goToOfHaveCourseOfLiveId = resultReally.liveId
            }
          } else if (resultReally.role === 2) {
            if (resultReally.resultStatus === 1) {
              if (Object.prototype.hasOwnProperty.call(resultReally, 'liveCourseId')) {
                // this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLETWOFORLC'
                this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
              } else {
                // this.$router.push({
                //   name: 'TeacherPicture',
                //   query: { liveId: resultReally.liveId, sourceofcome: 'sourcefromweb' }
                // })
                window.open(`https://www.livecourse.com/teacher/TeacherPicture?liveId=${resultReally.liveId}&sourceofcome=sourcefromweb`, '_blank')
              }
            }
            if (resultReally.resultStatus === 2) {
              if (Object.prototype.hasOwnProperty.call(resultReally, 'liveCourseId')) {
                // this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLETWOFORLC'
                this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
              } else {
                this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLETWO'
                this.dialogForHaveCourse = true
                this.goToOfHaveCourseOfLiveId = resultReally.liveId
              }
            }
          }
          return
        }
      } catch (err) {
        console.log(err)
      }
    },

    // 点击待开始直播  在两小时内出发改方法 教室身份参加
    async goClassBtns(res) {
      try {
        const { data: resultForGetInviteCodeInfo } = await this.$http.getInviteCodeInfo({ inviteCode: res.teacherJoinCode })
        console.log(resultForGetInviteCodeInfo)
        if (resultForGetInviteCodeInfo.code !== 200) return this.$message.error('当前网络异常，请检查网络！')
        if (resultForGetInviteCodeInfo.rt.resultStatus === -1) return this.$message.error('当前网络异常，请检查网络！')
        // codeSource 1：直播邀请码(学生邀请码/主讲邀请码) 2：课程邀请码 3：活动邀请码
        // type 1：主讲邀请码 2：助教邀请码 3：学生邀请码
        this.inviteCodeTypeForFlowOfOverall = resultForGetInviteCodeInfo.rt.codeSource
        this.inviteCodeTypeForFlowOfLive = resultForGetInviteCodeInfo.rt.type

        // 新增逻辑：
        // 需要判断是主讲收视，如果是，就走新的流程：
        if (this.inviteCodeTypeForFlowOfOverall === 1 && this.inviteCodeTypeForFlowOfLive === 1) {
          console.log('主讲码')

          const { data: result } = await this.$http.teacherJoinLive({
            inviteCode: res.teacherJoinCode,
            userName: this.userInfo.realname
          })
          console.log(result, 'teacherJoinLive')
          if (result.code !== 200) return this.$message.error('当前网络异常，请检查网络！')
          const { rt: resultReally } = result
          if (resultReally.resultStatus !== 1 && resultReally.resultStatus !== 2) return this.$message.error('当前网络异常，请检查网络！')
          // 如果发现当前用户在此直播当中是收视则不能进
          if (resultReally.role === 1) {
            if (resultReally.resultStatus === 1) {
              if (Object.prototype.hasOwnProperty.call(resultReally, 'liveCourseId')) {
                // this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLEONEFORLC'
                this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
              } else {
                // this.$router.push({
                //   name: 'StudentPicture',
                //   query: { liveId: resultReally.liveId, sourceofcome: 'sourcefromweb' }
                // })
                window.open(`https://www.livecourse.com/teacher/StudentPicture?liveId=${resultReally.liveId}&sourceofcome=sourcefromweb`, '_blank')
              }
            }
            if (resultReally.resultStatus === 2) {
              if (Object.prototype.hasOwnProperty.call(resultReally, 'liveCourseId')) {
                // this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLEONEFORLC'
                this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
              } else {
                this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLEONE'
                this.dialogForHaveCourse = true
                this.goToOfHaveCourseOfLiveId = resultReally.liveId
              }
            }
          } else if (resultReally.role === 2) {
            if (Object.prototype.hasOwnProperty.call(resultReally, 'liveCourseId')) {
              // this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLETWOFORLC'
              this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
            } else {
              this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLETWO'
              this.dialogForHaveCourse = true
              this.goToOfHaveCourseOfLiveId = resultReally.liveId
            }
          }
          return
        }

        if (this.inviteCodeTypeForFlowOfOverall === 1 && this.inviteCodeTypeForFlowOfLive === 2) {
          console.log('收视码')

          const { data: result } = await this.$http.teacherJoinLive({
            inviteCode: res.teacherJoinCode,
            userName: this.userInfo.realname
          })
          console.log(result, 'teacherJoinLive')
          if (result.code !== 200) return this.$message.error('当前网络异常，请检查网络！')
          const { rt: resultReally } = result
          if (resultReally.resultStatus !== 1 && resultReally.resultStatus !== 2) return this.$message.error('当前网络异常，请检查网络！')
          // 如果发现当前用户在此直播当中是主讲则不能进
          if (resultReally.role === 1) {
            if (Object.prototype.hasOwnProperty.call(resultReally, 'liveCourseId')) {
              // this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLEONEFORLC'
              this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
            } else {
              this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLEONE'
              this.dialogForHaveCourse = true
              this.goToOfHaveCourseOfLiveId = resultReally.liveId
            }
          } else if (resultReally.role === 2) {
            if (resultReally.resultStatus === 1) {
              if (Object.prototype.hasOwnProperty.call(resultReally, 'liveCourseId')) {
                // this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLETWOFORLC'
                this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
              } else {
                // this.$router.push({
                //   name: 'TeacherPicture',
                //   query: { liveId: resultReally.liveId, sourceofcome: 'sourcefromweb' }
                // })
                window.open(`https://www.livecourse.com/teacher/TeacherPicture?liveId=${resultReally.liveId}&sourceofcome=sourcefromweb`, '_blank')
              }
            }
            if (resultReally.resultStatus === 2) {
              if (Object.prototype.hasOwnProperty.call(resultReally, 'liveCourseId')) {
                // this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLETWOFORLC'
                this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
              } else {
                this.goToOfHaveCourseType = 'HAVECOURSEFORJOINOFROLETWO'
                this.dialogForHaveCourse = true
                this.goToOfHaveCourseOfLiveId = resultReally.liveId
              }
            }
          }
          return
        }
      } catch (err) {
        console.log(err)
      }
    },


    //跳转主站首页
    pushHome() {
      this.$router.push('/')
    },

    // 点击历史直播查看回放
    goToVideo(item) {
      // 直播回放直接进入，正在直播已登录可以进，未登录跳转到登录页
      if (item.liveStatus == 1) { // 正在直播
        if (localStorage.getItem('User_userInfo')) {
          const uuid = JSON.parse(localStorage.getItem('User_userInfo')).uuid || ''
          // 加入直播前先 +1
          const params = {
            studentInviteCode: item.studentInviteCode || '',
            userName: JSON.parse(localStorage.getItem('User_userInfo')).realname || '',
            uuid
          }
          this.$http.appJoinLive(params)
          // 加入直播
          window.open(`https://www.livecourse.com/teacher/IndividualStudent?liveId=${item.liveId}&uuid=${uuid}`, '_self')
        } else {
          let resultUrl = location.href
          location.href = `https://www.livecourse.com/api/simple-class/zhs/redirectUrlAndGetUserInfo?redirectUrl=${resultUrl}`
        }
      } else { // 直播回放
        const url = `https://tp.zhihuishu.com/#/playback?role=0&id=${item.liveId}&record=2`
        window.open(url, "_blank")
      }

    },

    //左侧按钮跳转
    funcLeft(key) {
      switch (key) {
        case 'addLive':
          window.open('https://www.livecourse.com/teacher/Home?typeForCreate=join', '_self')
          break
        case 'launchLive':
          this.launchLiveFunc()
          break
        case 'upDown':
          this.$refs['uploadVideo'].handleUpload();
          break
        case 'applyOpen':
          this.$router.push('/applyOpen')
          break
      }
    },
    async handleUploadSuccess(params) {
      console.log('params🔧:>>>>>>>', params, 'index.vue')
      const { data } = await this.$http.uploadVideo({
        videoId: params.videoId,
        filePath: params.filePath
      })
      if (data.code == 200 && data.rt.resultStatus == 1) {
        this.$message.success('上传成功')
        setTimeout(() => {
          this.pageInfo.pageNum = 1
          this.hisList = []
          this.getHistoryList()
        }, 3000)
      }
    },
    //下拉加载
    loadDates() {
      if (this.totalCount >= this.pageInfo.pageSize) {
        this.andMore = true
        setTimeout(async () => {
          this.pageInfo.pageNum++
          await this.getHistoryList()
          this.andMore = false
        }, 300)
      }
    },

    // 登出
    logoutBtn() {
      const source = this.getParams(window.location.search)
      if (source == 1) {
        this.$router.push('/historyLive')
        const resultUrl = location.href
        location.href = `https://www.livecourse.com/api/simple-class/zhs/logout?redirectUrl=${resultUrl}`
      } else {
        this.$store.commit('userInfo/Mutation_SET_ISLOGIN', false)
        this.$store.commit('userInfo/Mutation_REMOVE_USERINFO')
        localStorage.removeItem('User_userInfo')
        const resultUrl = location.href
        location.href = `https://www.livecourse.com/api/simple-class/zhs/logout?redirectUrl=${resultUrl}`
        this.$router.push('/')
      }
    },

    //复制
    copy(context) {
      if (context.teacherJoinCode) {
        var res = `${this.userInfo.realname}老师给你发送了"${context.liveName}"课堂邀请\n课堂时间：${this.timeFormat(context.startTime, 'YYYY/MM/DD')} (${dataUtils.getWeek(this.timeFormat(context.startTime, 'YYYY-MM-DD'))})   ${this.timeFormat(context.startTime, 'HH:mm')}-${this.timeFormat(context.endTime, 'HH:mm')}\n听课教室参加码：${context.assistantJoinCode}\n学生参加码：${context.studentJoinCode}\n学生点击链接进入课堂：https://www.livecourse.com/teacher/IndividualStudent?liveId=${context.liveId}`
      } else {
        var res = `"${context.liveName}"课堂回放观看方式\n打开"同步课堂网"：https://www.livecourse.com/home\n点击【加入直播】，输入回放参加码：${context.studentJoinCode}`
        // var res = `https://www.livecourse.com/playback?id=${context.liveId}`
      }
      const textarea = document.createElement('textarea')
      document.body.appendChild(textarea)	//	添加到body中
      textarea.value = res	//	给dom设置值
      textarea.select()	//	设置选中
      const copyFalse = document.execCommand('copy')
      this.$message.success('复制成功!')
      textarea.remove()	//	用完移除dom
    },

    //获取历史记录列表
    async getHistoryList() {
      const { data: msg } = await this.$http.historyList(this.pageInfo)
      this.loading = true
      if (msg.code === 200) {
        this.loading = false
        this.totalCount = msg.rt.totalCount
        this.hisList.push(...msg.rt.list)
        console.log('this.hisList🔧:>>>>>>>', this.hisList, 'index.vue')
      }
    },

    //获取待开始列表
    async getToStart() {
      const { data: msg } = await this.$http.toStartList()
      if (msg.code === 200) {
        this.startList = msg.rt
      }
    },


    //获取登录用户信息
    async init() {
      await this.getInit()
      await this.getToStart()
      await this.getHistoryList()
    },

    //获取翻转课传递过来的参数
    getParams(res) {
      let query = res.substring(1)
      let values = query.split('&')
      for (let i = 0; i < values.length; i++) {
        let pos = values[i].indexOf('=')
        if (pos === -1) continue
        // let paramName = values[i].substring(0,pos);
        return values[i].substring(pos + 1)
      }
    },

    //新增桌面端逻辑 获取用户信息
    async newUserInfo() {
      const { data: msg } = await this.$http.getLoginUserInfo()
      if (msg.code == 200) {
        this.$store.commit('userInfo/Mutation_SET_ISLOGIN', true)
        this.$store.commit('userInfo/Mutation_SET_USERINFO', msg.rt)
        this.getUserInfo()
        await this.$router.push('/historyLive')
      } else {
        await this.$router.push('/')
      }
    },

    //从桌面端到网页端唤醒登录
    async DesktopLogin(token) {
      console.log(token, 'token')
      const {data:msg} = await this.$http.goLogin(token)
      console.log(msg)
      if (msg.code === 200 && msg.rt.resultStatus === 1) {
        await this.newUserInfo()
      }
    },

    //请求获取用户信息
    async getInit() {
      const res = await this.$http.getLoginUserInfo()
      if (res.data.code != 200) {
        // 删除状态管理和本地存储
        this.$store.commit('userInfo/Mutation_SET_ISLOGIN', false)
        this.$store.commit('userInfo/Mutation_REMOVE_USERINFO')
        const result = this.getParams(window.location.search)
        if (result) {
          if (result == 1) {
            let resultUrl = location.href
            location.href = `https://www.livecourse.com/api/simple-class/zhs/redirectUrlAndGetUserInfo?redirectUrl=${resultUrl}`
          } else if (result[0] == 2) {
            await this.DesktopLogin(result.substring(1))
          } else {
            await this.$router.push('/')
          }
        } else {
          await this.$router.push('/')
        }
      } else {
        const result = this.getParams(window.location.search)
        if (result) {
          if (result[0] == 2) {
            await this.DesktopLogin(result.substring(1))
          } else {
            // 已登录
            // 添加状态管理并存储在本地
            this.$store.commit('userInfo/Mutation_SET_ISLOGIN', true)
            this.$store.commit('userInfo/Mutation_SET_USERINFO', res.data.rt)
            this.getUserInfo()
          }
        } else {
          // 已登录
          // 添加状态管理并存储在本地
          this.$store.commit('userInfo/Mutation_SET_ISLOGIN', true)
          this.$store.commit('userInfo/Mutation_SET_USERINFO', res.data.rt)
          this.getUserInfo()
        }
      }
    },


    getUserInfo() {
      const users = JSON.parse(localStorage.getItem('User_userInfo'))
      this.userInfo.avatar = users.avatar
      this.userInfo.realname = users.realname
      this.userInfo.schoolName = users.schoolName
    },

    async launchLiveFunc() {
      if (!localStorage.getItem('User_userInfo')) {
        let resultUrl = location.href.split('?')[0]
        resultUrl = resultUrl.lastIndexOf('/') === resultUrl.length - 1 ? resultUrl.slice(0, resultUrl.lastIndexOf('/')) : resultUrl
        location.href = `https://www.livecourse.com/api/simple-class/zhs/redirectUrlAndGetUserInfo?redirectUrl=${resultUrl}`
      } else {
        // authType 1学校 2企业 3培训机构   roleList 学校<1学生 2老师> 企业<1员工 2老师 3管理员>
        if (!JSON.parse(localStorage.getItem('User_userInfo')).authInfo) return this.$message.error('拥有老师权限才能发起直播，请联系管理员开通！')
        const authTypeItemForSchool = JSON.parse(localStorage.getItem('User_userInfo')).authInfo.find(item => item.authType === 1)
        if (!authTypeItemForSchool) this.$message.error('拥有老师权限才能发起直播，请联系管理员开通！')
        if (!(authTypeItemForSchool.roleList || []).includes(2)) return this.$message.error('拥有老师权限才能发起直播，请联系管理员开通！')
        this.$loading.show()
        const { data } = await this.$http.teacherQuickLive()
        this.$loading.hide()
        if (data.code !== 200) return this.$message.error(data.msg)
        const { rt } = data
        if (rt.resultStatus !== 1 && rt.resultStatus !== 2) return this.$message.error(rt.resultMessage)
        this.rtData = rt
        switch (rt.resultStatus) {
          case 1: // 创建直播成功
            window.open(`https://www.livecourse.com/teacher/StudentPicture?liveId=${rt.liveId}&sourceofcome=sourcefromweb`, '_blank')
            break
          case 2: // 您有一门正在进行中的课
            if (rt.role === 1) {
              if (Object.prototype.hasOwnProperty.call(rt, 'liveCourseId')) {
                // this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLEONEFORLC'
                this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
              } else {
                this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLEONE'
              }
            } else if (rt.role === 2) {
              if (Object.prototype.hasOwnProperty.call(rt, 'liveCourseId')) {
                // this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLETWOFORLC'
                this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
              } else {
                this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLETWO'
              }
            }
            this.dialogForHaveCourse = true
            this.goToOfHaveCourseOfLiveId = rt.liveId
            break
        }
      }
    },

    goToOfHaveCourse() {
      let name = ''
      switch (this.goToOfHaveCourseType) {
        case 'HAVECOURSEFORCREATEOFROLEONE':
        case 'HAVECOURSEFORJOINOFROLEONE':
          name = 'StudentPicture'
          break
        case 'HAVECOURSEFORCREATEOFROLETWO':
        case 'HAVECOURSEFORJOINOFROLETWO':
          name = 'TeacherPicture'
          break
        case 'HAVECOURSEFORJOINOFROLEONEFORLC':
          name = 'StudentPictureForLC'
          break
        case 'HAVECOURSEFORJOINOFROLETWOFORLC':
          name = 'TeacherPictureForLC'
          break
      }
      this.dialogForHaveCourse = false
      setTimeout(() => {
        window.open(`https://www.livecourse.com/teacher/${name}?liveId=${this.goToOfHaveCourseOfLiveId}&sourceofcome=sourcefromweb`, '_blank')
      }, 400)
    },
    //日期时间转化过滤器
    timeFormat(str, def = 'YYYY-MM-DD HH:mm:ss') {
      return moment(str).format(def)
    }

  }
  ,
  filters: {
    //日期时间转化过滤器
    dateFormat(str, def = 'YYYY-MM-DD HH:mm:ss') {
      return moment(str).format(def)
    }
  },
  //当离开页面时，清除倒计时
  beforeDestroy() {
    clearInterval(this._interval)
  }


}
</script>

<style scoped lang='less'>
.box {
  background-color: #000c1d;
  height: 100vh;
}

//头部
.header {
  display: flex;
  height: 80px;
  background-color: #161722;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  width: 100%;
  position: fixed;
  top: 0;

  .left {
    display: flex;
    align-items: center;

    .logo-icon {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    .logo-font {
      font-size: 24px;
      font-weight: 500;
      color: #FFFFFF;
      margin-left: 8px;
      cursor: pointer;
    }
  }

  .right {
    cursor: pointer;

    ::v-deep .submit-btn {
      width: 96px;
      height: 40px;
      border: 0;
      border-radius: 4px;
      background: #1672FF;
      margin-left: 20px;

      &:hover {
        background: #3283FF;
      }

      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 14px;
      }
    }

    .picture {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    .downArray {
      width: 24px;
      height: 24px;
    }
  }
}

.el-popover {
  .loginInfo {
    .top {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 28px;

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      .user-name {
        font-size: 14px;
        font-weight: 400;
        color: #1E2228;
        margin: 8px 0;
        line-height: 14px;
      }

      .school {
        font-size: 12px;
        font-weight: 400;
        color: #9FA2B1;
        line-height: 12px;
      }
    }

    .line-gap {
      width: 100%;
      height: 1px;
      background: #EFF3F7;
      position: absolute;
      left: 0;
      bottom: 30px;
    }

    .exit-wrap {
      width: 100%;
      height: 30px;
      align-items: center;
      cursor: pointer;
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: 0 0 5px 5px;

      &:hover {
        background-color: #F5F5F5;
      }

      img {
        width: 16px;
        height: 16px;
        margin-left: 16px;
      }

      .label {
        font-size: 14px;
        font-weight: 400;
        color: #1E2228;
        margin-left: 8px;
      }
    }
  }
}

//main
.main {
  display: flex;
  background-color: #000c1d;

  .mainL {
    flex: 1;
    background-color: #161722;
    padding: 5px;
    position: fixed;
    top: 80px;
    left: 0;
    height: 100%;
    width: 350px;
    margin-top: 2px;

    .first-box {
      display: flex;
      margin-top: 5px;
      justify-content: space-around;

      .box-css {
        width: 153px;
        height: 153px;
        cursor: pointer;
        background-color: #1b263c;
        flex-direction: column;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #FFFFFF;

        img {
          width: 48px;
          height: 48px;
        }

        p {
          margin-top: 10px;
        }

        &:hover {
          background-color: #3c3f41;
        }
      }
    }

    .two-box {
      display: flex;
      margin-top: 14px;
      justify-content: space-around;

      .box-css {
        width: 153px;
        height: 153px;
        cursor: pointer;
        background-color: #1b263c;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #FFFFFF;

        img {
          width: 48px;
          height: 48px;
        }

        p {
          margin-top: 10px;
        }

        &:hover {
          background-color: #3c3f41;
        }
      }
    }
  }

  .mainR {
    flex: 3.3;
    padding: 10px 15px;
    background-color: #000c1d;
    margin-left: 350px;
    margin-top: 80px;

    .maiNever {
      color: #FFFFFF;
      height: 85vh;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center
    }

    .toStart {
      .title {
        color: #FFFFFF;
        font-size: 20px;
        margin-left: 15px;
      }

      .content {
        margin-top: 10px;
        padding: 10px;
        background-color: #161722;

        .content-ge {
          height: 80px;
          padding: 7px;
          border-bottom: 2px solid #202129;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: space-between;

          &:hover {
            background-color: #21222d;
          }

          .cssLeft {
            display: flex;
            align-items: center;
          }

          .cssRight {
            color: #9FA2B1;
            font-size: 14px;
            margin-right: 20px;
          }

          .ge-title {
            margin-left: 20px;

            .ge-one {
              display: flex;
              align-items: center;

              .titles {
                font-size: 14px;
                color: #ffffff;
              }

              .titlesW {
                font-size: 14px;
                color: #ffffff;
                width: 300px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
              }

              .time {
                margin-left: 15px;
                font-size: 14px;
                color: #9FA2B1;
              }
            }

            .ge-two {
              margin-top: 5px;
              display: flex;
              align-items: center;

              .spanBox {
                display: flex;
                align-items: center;

                .spanOneCode {
                  color: #9FA2B1;
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                }

                .spanCode {
                  display: flex;
                  margin-left: 20px;
                  color: #9FA2B1;
                  font-size: 14px;
                  align-items: center;
                }

                .code {
                  color: #ffffff;
                  width: 60px;
                  display: block;
                }
              }


              .copy {
                cursor: pointer;
                color: #1672FF;
                font-size: 14px;
                margin-left: 15px;

                &:hover {
                  color: #5296FF;
                }
              }

            }
          }
        }


      }

    }

    .historyLive {
      margin-top: 10px;

      .hisTitle {
        color: #FFFFFF;
        font-size: 20px;
        margin-left: 15px;
      }

      .content {
        margin-top: 10px;
        padding: 10px;
        background-color: #161722;

        .content-ge {
          height: 80px;
          padding: 7px;
          border-bottom: 2px solid #202129;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: space-between;

          &:hover {
            background-color: #21222d;
          }

          .cssLeft {
            display: flex;
            align-items: center;
          }

          .cssRight {
            color: #9FA2B1;
            font-size: 14px;
            margin-right: 20px;
          }

          .ge-title {
            margin-left: 20px;

            .ge-one {
              display: flex;
              align-items: center;

              .titles {
                font-size: 14px;
                color: #ffffff;
              }

              .titlesW {
                font-size: 14px;
                color: #ffffff;
                width: 300px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
              }

              .time {
                margin-left: 15px;
                font-size: 14px;
                color: #9FA2B1;
              }
            }

            .ge-two {
              margin-top: 5px;
              display: flex;
              align-items: center;

              .spanOneCode {
                color: #9FA2B1;
                font-size: 14px;
                width: 150px;
                display: block;
              }

              .code {
                color: #ffffff;
              }

              .copy {
                cursor: pointer;
                color: #1672FF;
                font-size: 14px;

                &:hover {
                  color: #5296FF;
                }
              }

            }
          }
        }

        .andMore {
          color: #FFFFFF;
          text-align: center;
          margin: 20px 0;
        }


      }
    }

  }
}

// 课程进行中
.dialog_inner_container_for_have_course {
  padding: 20px 17px;
  padding-bottom: 25px;

  .dialog_inner_container_for_have_course_header {
    width: 100%;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1E2228;
  }

  .dialog_inner_container_for_have_course_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dialog_inner_container_for_have_course_body_img {
      width: 150px;
      height: 105px;
      display: block;
      margin-top: 20px;
    }

    .dialog_inner_container_for_have_course_body_title {
      width: 232px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2A2A2A;
      line-height: 20px;
      margin-top: 32px;
    }

    .dialog_inner_container_for_have_course_body_btn {
      width: 104px;
      height: 40px;
      background: #1672FF;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      cursor: pointer;
      user-select: none;

      &:hover {
        background: #1367e6;
      }

      &:active {
        background: #1672FF;
      }
    }
  }
}

.dialog_inner_container {
  width: 100%;
  height: auto;
  position: relative;

  .dialog_inner_container_top {
    width: 100%;
    height: 135px;
    background: url(https://image.zhihuishu.com/zhs/ablecommons/zhangying/202112/ba9af54bdda24b3ea299e27a5c9f87a3.png) no-repeat;
    background-size: cover;
    padding: 20px;
    box-sizing: border-box;

    .dialog_inner_container_top_title {
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }

    .dialog_inner_container_top_time {
      text-align: center;
      font-size: 17px;
      color: rgba(255, 255, 255, 0.7);
      line-height: 30px;
    }
  }

  .dialog_inner_container_nav {
    background: #fff;
    padding-top: 60px;
    border-radius: 10px;

    .dialog_inner_container_nav_img {
      width: 86px;
      position: absolute;
      top: 90px;
      left: 50%;
      margin-left: -43px;
    }

    .dialog_inner_container_nav_content_explain {
      font-size: 14px;
      color: #9c9c9c;
      text-align: center;
    }

    .dialog_inner_container_nav_content_time {
      padding-top: 30px;

      .title {
        text-align: center;
        font-size: 14px;
        color: #5d82ff;
      }

      .time {
        text-align: center;
        font-size: 20px;
        color: #5d82ff;
        font-weight: bold;
        margin-top: 10px;
      }
    }

    .dialog_inner_container_nav_footer_btn {
      width: 100px;
      height: 40px;
      background: #5d82ff;
      border-radius: 8px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
      line-height: 40px;
      margin: 40px auto 30px;
      cursor: pointer;

      &:hover {
        background-color: #5172e1 !important;
      }

      &:active {
        background-color: #5f82f7 !important;
      }
    }
  }
}
</style>
