<template>
    <div>
        <el-drawer :visible.sync="drawer" :before-close="closeDrawer" :direction="direction" :modal="false" size="35%" :show-close="false"
            :withHeader="false">
            <div class="drawer-wrapper">
                <div class="class-header">
                    <div class="avatar">
                        <img :src="classInfo.avatar" />
                    </div>
                    <div class="base-info">
                        <div class="class-name">{{classInfo.courseName}}</div>
                        <div class="other-info">
                            <div>开课<span>{{ classInfo.terms }}</span>学期</div>
                            <div>播放<span>{{ formatNumber(classInfo.vv) }}</span></div>
                        </div>
                    </div>
                </div>
                <div class="class-des-box" v-if="classInfo.courseIntroduce">
                    <div class="class-des-title">课程介绍</div>
                    <div class="class-des">{{ classInfo.courseIntroduce }}</div>
                </div>
                <div class="class-list-box">
                    <div class="class-list-header">
                        <div class="class-list-title">回放选集</div>
                        <div class="class-nums">共<span>{{ classInfo.liveTotal || 0 }}</span>期</div>
                    </div>
                    <!-- 课程介绍显示与否，会影响下方列表高度 -->
                    <div class="class-list" :class="classInfo.courseIntroduce ? 'has-des' : ''">
                        <el-scrollbar>
                            <div class="class-item" v-for="(item, index) in liveInfoList" :key="index" @click="switchLive(item)">
                                <div class="class-item-cover">
                                    <img :src="item.imageUrl || require('@/assets/audioAI/video-cover.jpg') " />
                                </div>
                                <div class="class-item-info">
                                    <div class="class-item-name">
                                        <div :title="`第${index + 1}期 ${item.liveName}`">{{ `第${index + 1}期 ${item.liveName}` }}</div>
                                        <img v-if="item.liveId == liveId" src="@/assets/audioAI/playing.gif" />
                                    </div>
                                    <div class="class-item-size">{{ (item.videoSize / 1024 / 1024).toFixed(2) }}MB</div>
                                    <div class="class-item-time">{{ item.startTime }}</div>
                                </div>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { formatNumber } from '@/utils/toolsForCommon'
export default {
    props: ['drawer', 'liveId'],
    data() {
        return {
            direction: 'rtl',
            classInfo: {},
            liveInfoList: []
        }
    },
    watch: {
        drawer: {
            handler(val) {
                val && this.getClassList()
            }
        }
    },
    methods: {
        formatNumber,
        closeDrawer() {
            this.$emit('update:drawer', false)
        },
        async getClassList() {
            const params = {
                liveId: this.liveId
            }
            const { data } = await this.$http.getSeriesLiveInfo(params)
            this.classInfo = data.rt
            if (data.rt.liveInfoList && data.rt.liveInfoList.length) {
                this.liveInfoList = data.rt.liveInfoList.filter((item,index)=>{
                  item.num = index
                  return item.videoSize
                })
            }

        },
        switchLive(item) {
            this.$emit('switchLive', item)
        }
    }
}
</script>
<style lang="less" scoped>
.drawer-wrapper {
    background-color: #161722;
    height: 100%;
    overflow: hidden;
    .class-header {
        padding: 32px 16px 20px;
        border-bottom: 1px solid #303235;
        display: flex;
        .avatar {
            width: 40px;
            height: 40px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .base-info {
            margin-left: 8px;
            .class-name {
                font-size: 16px;
                color: #fff;
                font-weight: 500;
                line-height: 16px;
            }
            .other-info {
                margin-top: 8px;
                display: flex;
                font-size: 14px;
                color: #9fa2b1;
                line-height: 14px;
                span {
                    color: #fff;
                }
                div:first-child {
                    margin-right: 8px;
                }
            }
        }
    }
    .class-des-box {
        padding: 20px 24px;
        border-bottom: 1px solid #303235;
        .class-des-title {
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
        }
        .class-des {
            margin-top: 10px;
            font-size: 14px;
            line-height: 20px;
            color: #9fa2b1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
    }
    .class-list-box {
        padding: 16px 8px;
        .class-list-header {
            display: flex;
            justify-content: space-between;
            padding: 0 8px;
            margin-bottom: 8px;
            .class-list-title {
                color: #fff;
                font-size: 16px;
                font-weight: 500;
            }
            .class-nums {
                color: #9da0af;
                span {
                    color: #1672ff;
                }
            }
        }
        .class-list {
            // padding: 0 8px;
            border-radius: 4px;
            height: calc(100vh - 139px);
            position: relative;
            overflow: hidden;

            display: flex;
            flex-direction: column;
            // overflow-y: auto;
            &.has-des {
                height: calc(100vh - 139px - 127px);
            }
        }
        .class-item {
            display: flex;
            border-bottom: 1px solid #303235;
            padding: 8px;
            font-size: 14px;
            line-height: 16px;
            color: #9fa2b1;
            cursor: pointer;
            &:hover {
                background-color: rgba(255, 255, 255, 0.05);
            }
            &-cover {
                width: 112px;
                height: 63px;
                margin-right: 8px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            &-info {
                flex: 1;
                width: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            &-name {
                color: #fff;
                display: flex;
                align-items: center;
                img {
                    width: 15px;
                    height: 15px;
                    margin-left: 12px;
                }
                div {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            &-size {
                height: 16px;
            }
            &-time {
                height: 16px;
            }
        }
    }
}
.class-list /deep/ .is-horizontal {
    display: none;
}
.class-list /deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
    margin-bottom: 0 !important;
}
</style>
