<template>
  <div class="commonFooter-wrap flex">
    <div class="title PFMC">同步课堂网</div>
    <div class="describe PFMC">真正的教室互联网</div>
    <div class="btn-wrap PFMC">
      <el-button @click="applyForTrial" class="apply-btn" type="primary">申请试用</el-button>
      <el-button @click="ContactUs" class="about-btn" plain>联系我们</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commonFooter',
  methods: {
    applyForTrial () {
      this.$router.push('/applyOpen')
    },
    ContactUs () {
      window.open('https://www.zhihuishu.com/', '_self')
    }
  }
}
</script>

<style lang="less" scoped>
.commonFooter-wrap{
  height: 330px;
  background: url('https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/3d93b0db93e144fd93bdfdbcbcea157d.png') no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title, .describe{
    font-size: 40px;
    font-weight: 500;
    color: #1F2329;
    line-height: 48px;
  }
  .describe{
    font-size: 18px;
    line-height: 26px;
    margin: 16px 0;
  }
  ::v-deep .apply-btn{
    width: 154px;
    height: 54px;
    border: 0;
    background: #1672FF;
    &:hover{
        background: #3283FF !important;
      }
    span{
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 25px;
    }
  }
  ::v-deep .about-btn{
    width: 154px;
    height: 54px;
    background: #FFFFFF;
    border: 2px solid #1672FF;
    span{
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1672FF;
      line-height: 25px;
    }
    &:hover{
      border: 2px solid #5296FF;
      background-color: #F1F7FF;
    }
  }
}
</style>
