<template>
  <div class='playback-container'>
    <commonHeader
      ref='headers'
      leftTitleAndWhiteBtn
      targetPage="playback"
      :iconStatus='false'
      :copyStatus='true'
      :comment='liveInfos'
      :liveId='liveId'
      :record='$route.query.record'
      :editBtnShowAboutBackWord="editBtnShowAboutBackWord"
      :flagForSyncToCourseContainer="flagForSyncToCourseContainer"
    />
    <AudioAI
      theme='dark'
      :liveId='liveId'
      :isTranscode='isTranscode'
      :isAutoCutting='isAutoCutting'
      :isSeries='isSeries'
      :videoList='videoList'
      :seriesLiveList='seriesLiveList'
      @cutByMySecond='cutByMySecond'
      ref="refForAudioAIInPlayBack"
    />
    <CutDialog
      v-if="showCutDialog"
      :show.sync="showCutDialog"
      :source="videoIdSecond"
      :uuid="uuid"
      :special="special"
      :countToCut="countToCut"
      @handleUpdate="getVideoList"
      :indexs='$route.query.indexs'
      :userId="userId"
    />
  </div>
</template>
<script>
import commonHeader from '@/components/common/commonHeader.vue'
import AudioAI from '@/components/AudioAI'
import { mapGetters } from 'vuex'
import CutDialog from '@/views/AIExperience/components/CutDialog'
export default {
  components: {
    AudioAI,
    commonHeader,
    CutDialog
  },
  data() {
    return {
      videoList: [],
      liveId: '',
      isSeries: false, //是否是系列课
      timer: null,
      isTranscode: false, //是否转码
      isAutoCutting: false, //是否展示视频列表右侧"AI切片中"提示
      lastModifiedTime: '', //上次更新时间
      seriesLiveList: [],  //系列课列表

      //手动剪辑相关
      showCutDialog: false, //手动剪辑弹窗
      videoIdSecond: '',
      uuid: '',
      special: '',
      countToCut: 0,
      userId: 0,

      //直播信息头
      liveInfos: {},
      // 直播详情：
      liveDetail: {},
      needShowSyncToCourseContainer: false,
      videoListGetCount: 0
    }
  },
  computed: {
    ...mapGetters(['isLogin', 'userInfo']),
    ...mapGetters('userInfo', ['userInfoGetter']),
    editBtnShowAboutBackWord: function () {
      let editBtnShowAboutBackWord = false
      if (!this.liveDetail || !this.userInfoGetter) return false
      if (this.liveDetail.userId && (this.liveDetail.userId === this.userInfoGetter.userId)) {
        editBtnShowAboutBackWord = true
      } else {
        editBtnShowAboutBackWord = false
      }
      return editBtnShowAboutBackWord
      // return true
    },
    flagForSyncToCourseContainer: function () {
      return this.editBtnShowAboutBackWord
      // return true
    }
  },
  watch: {
    'showCutDialog': {
      handler: function (newVal) {
        this.$nextTick(() => {
          if (!newVal && this.needShowSyncToCourseContainer && (this.videoListGetCount !== 1)) {
            this.$refs.headers.dialogFlagForSyncToCourseContainerTrue()
            this.needShowSyncToCourseContainer = false
          }
        })
      },
      immediate: true
    }
  },
  mounted() {
    this.liveId = this.$route.query.id
    this.getVideoList()
    this.getDetail()
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer)
    this.$refs.headers.editCancelForWord()
  },
  methods: {
    // 手动剪辑按钮点击事件
    async cutByMySecond(videoId, isSample) {
      // 需登录
      if (this.isLogin) {
        //点击手动剪辑需发个验证请求
        await this.$http.getInform({liveId:this.liveId})
        // 如果是示例视频，调用上传接口进行关联
        if (isSample) {
          const params = {
            videoId,
            videoName: '示例视频',
            videoDuration: 0
          }
          await this.$http.uploadVideo(params)
        }
        this.videoIdSecond = videoId
        // const userInfo = JSON.parse(localStorage.getItem('User_userInfo') || '')
        const userInfo = JSON.parse(this.userInfo || '')
        this.uuid = userInfo.uuid
        this.userId = userInfo.userId
        this.special = isSample ? 1 : 0
        this.countToCut ++
        this.showCutDialog = true
      } else {
        this.login()
      }
    },

    // 登录
    login() {
      this.$refs['headers'].loginBtn()
    },


    async getDetail() {
      const { data: msg } = await this.$http.getDetails({ liveId: this.liveId })
      this.liveInfos = {
        liveName: msg.rt.courseName,
        startTime: msg.rt.actualStartTime,
        teacherName: msg.rt.teacherName,
        studentInviteCode:msg.rt.studentInviteCode,
        statusShow: true
      }
      this.liveDetail = msg.rt || {}
    },

    // 获取视频列表
    async getVideoList(cb) {
      const params = {
        liveId: this.liveId
      }
      const { data } = await this.$http.getAIVideoResultByLiveId(params)
      if (data.code == 200 && data.rt) {
        // 更新时间相同的话，不更新数据，防止播放中更新数据导致播放停止
        if (this.lastModifiedTime != data.rt.lastModifiedTime) {
          this.videoList = data.rt.videoList
          this.lastModifiedTime = data.rt.lastModifiedTime
          this.needShowSyncToCourseContainer = true
          this.videoListGetCount++
        }
        this.isSeries = data.rt.isSeries
        if (this.isSeries) {
          this.seriesLiveList = data.rt.seriesLiveList
        }
        data.rt.videoList.length && (this.isTranscode = data.rt.videoList[0].isTranscode)
        this.isAutoCutting = data.rt.isAutoCutting
        // 是否需要轮询查询
        if (data.rt.isPolling && !this.timer) {
          this.pollingGet()
        }
        // 结束轮询
        if (!data.rt.isPolling && this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }
      }
      cb && cb()
    },
    // 轮询获取切片结果
    pollingGet() {
      this.timer = setInterval(() => {
        this.getVideoList()
      }, 10000)
    }
  }
}
</script>
<style lang='less' scoped>
.playback-container {
  background-color: #161722;
  min-height: 100%;
}
</style>
