<template>
    <div class="video-list-constainer">
        <div class="video-list-header">
            <img src="@/assets/audioAI/AI-icon.png" />
            <div>AI主题切片</div>
            <!-- 主题指切片数量，需减去示例视频数量 -->
            <div class="video-title-des">（检测到<span>{{ sumOfExampleVideo > 0 ? videoList.length - sumOfExampleVideo : 0 }}</span>段主题）</div>
        </div>
        <div class="video-lsit">
            <div class="video-item" @click="switchVideo(item, index)" :class="item.videoId == videoId ? 'active' : ''" v-for="(item, index) in videoList"
                :key="index">
                <div class="video-cover-box">
                    <img v-if="item.videoType == 3 && !item.imageUrl" :src="originalVideoImage" />
                    <img v-else :src="item.imageUrl || require('@/assets/audioAI/video-cover.jpg')" />
                </div>
                <template v-if="item.videoType != 3">
                    <!-- 有封面正常展示 -->
                    <div class="video-name" v-if="item.imageUrl">原视频</div>
                    <!-- 否则展示审核中 -->
                    <div class="review-tips" v-else>
                        <div>视频审核中…</div>
                        <div>1-3分钟后推送</div>
                    </div>
                </template>
                <template v-else>
                    <!-- 有封面正常展示 -->
                    <div class="video-name">{{ `视频切片${index - sumOfExampleVideo + 1}：${item.title}` }}</div>
                </template>
                <!-- hover蒙层 -->
                <div class="item-mask"></div>
            </div>
            <!-- 开启自动剪辑且只返回了原视频，提示正在切片中 -->
            <div class="cutting-tips" v-if="isAutoCutting && onlyHasOriginalVideo" :style="{color: $route.name === 'AIExperience' ? '#1f2329' : '#FFF'}">
                <img src="@/assets/audioAI/warning-icon.png" />
                <div>AI视频切片中…系统将在1-3分钟后推送生成的视频</div>
            </div>
        </div>
        <div class="tools">
            <el-button class="btn cut-btn" @click="cutByMyself" v-if="$route.name == 'AIExperience'">
                <div class="btn-slot">
                    <img class="icon" src="@/assets/audioAI/cut-icon.png" />
                    <span>手动剪辑</span>
                </div>
            </el-button>
            <el-button class="btn disabled-btn" @click="handleUpload" disabled v-if="$route.name == 'AIExperience'">
                <div class="btn-slot disabled-btn-slot">
                    <img class="icon" src="@/assets/audioAI/upload-icon.png" />
                    <span>上传本地视频，体验AI剪辑</span>
                </div>
            </el-button>
            <el-button class="btn experience" v-if="$route.name == 'playback' && !rts" @click="$router.push('AIExperience')">
                <div class="btn-slot">
                    <img class="icon" src="@/assets/audioAI/AI-icon.png" />
                    <span>体验AI剪辑</span>
                </div>
            </el-button>
          <el-button class="btn experience" v-if="$route.name == 'playback' && rts" @click="cutByMyself(1)">
            <div class="btn-slot">
              <img class="icon" src="@/assets/audioAI/AI-icon.png" />
              <span>手动剪辑</span>
            </div>
          </el-button>
        </div>
    </div>
</template>
<script>
import { deepClone } from '@/utils/toolsForCommon'
export default {
    props: {
        videoList: {
            type: Array,
            default: []
        },
        videoId: {
            type: String | Number,
            default: ''
        },
        sumOfExampleVideo: {
            type: String | Number,
            default: 0
        },
        isAutoCutting: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            onlyHasOriginalVideo: false,
            originalVideoImage: '',
            rts:''
        }
    },
    watch: {
        videoList: {
            handler(val) {
                if (val && val.length) {
                    let cutVideoList = val.filter(item => {
                        if (item.videoType != 3) {
                            this.originalVideoImage = item.imageUrl
                        }
                        return item.videoType == 3
                    })
                    this.onlyHasOriginalVideo = !cutVideoList.length
                }
            }
        }
    },
  mounted() {
      this.getFilmStatus()
  },
  methods: {
      //获取该直播是否对应是手动剪辑
      async getFilmStatus(){
        const {data:msg} = await this.$http.getFilmEdit({liveId:this.$route.query.id})
        if(msg.code==200){
          this.rts = msg.rt
        }
      },

        handleUpload() {
            this.$emit('handleUpload')
        },
        cutByMyself(n) {
        if(n==1){
          this.$emit('cutByMyself',n)
        }else{
          this.$emit('cutByMyself')
        }
        },
        switchVideo(item, index) {
            let curItem = deepClone(item)
            if(curItem.videoId == this.videoId) return
            if (curItem.status == 4) return
            if(!curItem.imageUrl) {
                curItem.imageUrl = this.originalVideoImage
            }
            if(curItem.videoType == 3) {
                curItem.title = `视频切片${index - this.sumOfExampleVideo + 1}：${item.title}`
            }
            this.$emit('switchVideo', curItem)
        }
    }
}
</script>
<style lang="less" scoped>
.video-list-constainer {
    margin-top: 30px;
    .video-list-header {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: var(--color-white);
        font-weight: 500;
        img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
        .video-title-des {
            font-size: 14px;
            font-weight: normal;
            span {
                font-size: 16px;
                font-weight: 500;
                color: #1672ff;
            }
        }
    }
    .video-lsit {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .video-item {
            margin-right: 16px;
            width: calc((100% - 48px) / 4);
            // height: 146px;
            height: calc(9*(100% - 48px)/64);
            position: relative;
            margin-top: 16px;
            border-radius: 8px;
            overflow: hidden;
            border: 4px solid transparent;
            cursor: pointer;
            &:nth-child(4n) {
                margin-right: 0;
            }
            &.active {
                border-color: #ff8316;
            }
            .item-mask {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: rgba(0, 0, 0, 0.05);
                transition: all 0.6s;
                opacity: 1;
                z-index: 1;
            }
            &:hover .item-mask {
                background-color: rgba(0, 0, 0, 0.3);
            }
        }
        .video-cover-box {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                border-radius: 4px;
                object-fit: cover;
            }
        }
        .video-name {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 30px;
            line-height: 30px;
            padding-left: 12px;
            width: 100%;
            font-size: 14px;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.6);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-radius: 0 0 4px 4px;
        }
        .review-tips {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            line-height: 25px;
            font-size: 14px;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .cutting-tips {
            height: 48px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #1f2329;
            background-color: rgba(255, 131, 22, 0.2);
            width: 484px;
            padding: 0 16px;
            border-radius: 2px;
            margin-left: 30px;
            img {
                width: 14px;
                height: 14px;
                margin-right: 8px;
            }
        }
    }
    .tools {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        .btn {
            position: relative;
            border: 2px solid var(--color-primary);
            background-color: var(--color-black);
            font-size: 14px;
            padding: 14px 30px;
        }
        .disabled-btn {
            &:hover {
                border-color: #1672ff;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.1);
            }
            // &::before {
            //     position: absolute;
            //     content: '';
            //     width: 100%;
            //     height: 100%;
            //     top: 0;
            //     left: 0;
            //     background: rgba(0, 0, 0, 0.2);
            // }
        }
        .btn-slot {
            display: flex;
            align-items: center;
            color: var(--color-primary);
            .icon {
                width: 24px;
                height: 24px;
                margin-right: 12px;
            }
        }
        // .disabled-btn-slot {
        //     color: #ddd;
        //     .icon {
        //         color: #ddd;
        //     }
        // }
        .cut-btn {
            margin-right: 100px;
        }
        .experience:hover {
            border-color: #1672ff;
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
}
</style>
